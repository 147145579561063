import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { countries } from "../utils/countries";
import config from "../config";

const sectionStyle = {
  backgroundColor: "#f8f9fa",
  padding: "15px",
  borderRadius: "8px",
  marginBottom: "20px",
};

const sectionTitleStyle = {
  fontSize: "1.1rem",
  fontWeight: "600",
  marginBottom: "15px",
  color: "#495057",
};

// Update the required field style
const requiredLabel = {
  position: "relative",
};

const requiredStar = {
  color: "red",
  marginLeft: "4px",
};

// Add a helper component for required labels
const RequiredLabel = ({ children }) => (
  <Form.Label style={requiredLabel}>
    {children}
    <span style={requiredStar}>*</span>
  </Form.Label>
);

const AddStudentModal = ({
  show,
  onHide,
  onStudentAdded,
  studentToEdit,
  isEditing,
  user,
  subAgents,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    travel_doc_no: "",
    nationality: "",
    university_id: "",
    counselor_id: !isEditing ? user?.id?.toString() : "",
    student_year: "",
  });
  const [universities, setUniversities] = useState([]); // Add universities state
  const [counselors, setCounselors] = useState([]); // Add counselors state
  const [selectedSubAgent, setSelectedSubAgent] = useState("");
  const [otherSubAgentName, setOtherSubAgentName] = useState("");

  // Fetch universities when modal opens
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await fetch(`${config.API_URL}/admin/universities`, {
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setUniversities(data);
        }
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    const fetchCounselors = async () => {
      try {
        const response = await fetch(`${config.API_URL}/users`, {
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          // Show all counselors if user is admin or has see_all_counselors permission
          if (
            user.is_admin ||
            (user.permissions && user.permissions.includes("see_all_counselors"))
          ) {
            // Remove the filter that excludes admin users
            const counselors = data;
            setCounselors(counselors);
          } else {
            // If user doesn't have permission, only show themselves
            setCounselors([data.find((u) => u.id === user.id)].filter(Boolean));
          }
        }
      } catch (error) {
        console.error("Error fetching counselors:", error);
      }
    };

    if (show) {
      fetchUniversities();
      fetchCounselors(); // Always fetch counselors, not just for admins
    }
  }, [show, user?.is_admin, user?.id, user?.permissions]);

  // Update the editing useEffect
  useEffect(() => {
    if (isEditing && studentToEdit) {
      const universityId = universities.find(
        (u) => u.name === studentToEdit.university
      )?.id;

      const formDataToSet = {
        name: studentToEdit.name || "",
        email: studentToEdit.email || "",
        phone_number: studentToEdit.phone_number || "",
        travel_doc_no: studentToEdit.travel_doc_no || "",
        nationality: studentToEdit.nationality || "",
        university_id: universityId ? universityId.toString() : "",
        counselor_id: studentToEdit.user_id?.toString() || "",
        student_year: studentToEdit.student_year || "",
      };

      setFormData(formDataToSet);

      // Set sub-agent data
      if (studentToEdit.sub_agent_name) {
        // Check if the sub-agent exists in our database
        const existingSubAgent = subAgents.find(
          (agent) => agent.name === studentToEdit.sub_agent_name
        );
        if (existingSubAgent) {
          setSelectedSubAgent(studentToEdit.sub_agent_name);
          setOtherSubAgentName("");
        } else {
          setSelectedSubAgent("Other");
          setOtherSubAgentName(studentToEdit.sub_agent_name);
        }
      } else {
        setSelectedSubAgent("");
        setOtherSubAgentName("");
      }
    } else if (!isEditing) {
      setFormData({
        name: "",
        email: "",
        phone_number: "",
        travel_doc_no: "",
        nationality: "",
        university_id: "",
        counselor_id: user?.id?.toString() || "",
        student_year: "",
      });
      setSelectedSubAgent("");
      setOtherSubAgentName("");
    }
  }, [isEditing, studentToEdit, universities, user?.id, subAgents]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        nationality: formData.nationality.toUpperCase(),
        sub_agent_name:
          selectedSubAgent === "" || selectedSubAgent === "Without Sub-agent"
            ? ""
            : selectedSubAgent === "Other"
            ? otherSubAgentName
            : selectedSubAgent,
      };

      const url = isEditing
        ? `${config.API_URL}/students/${studentToEdit.id}`
        : `${config.API_URL}/students`;

      const response = await fetch(url, {
        method: isEditing ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(dataToSubmit),
      });

      const data = await response.json();

      if (response.ok) {
        onStudentAdded(data, isEditing);
        onHide();
        setFormData({
          name: "",
          email: "",
          phone_number: "",
          travel_doc_no: "",
          nationality: "",
          university_id: "",
          counselor_id: "",
          student_year: "",
        });
        setSelectedSubAgent("");
        setOtherSubAgentName("");
      } else {
        const errorMessage =
          data.error || "Failed to save student. Please try again.";
        if (data.existing_student) {
          const student = data.existing_student;
          const detailMessage = `\n\nExisting student details:\nName: ${
            student.name
          }\nTravel Document: ${student.travel_doc_no}\nUniversity: ${
            student.university || "Not specified"
          }`;
          alert(errorMessage + detailMessage);
        } else {
          alert(errorMessage);
        }
        console.error(
          isEditing ? "Failed to update student:" : "Failed to add student:",
          errorMessage
        );
      }
    } catch (error) {
      console.error(
        isEditing ? "Error updating student:" : "Error adding student:",
        error
      );
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleChange = (e) => {
    const value =
      e.target.name === "travel_doc_no"
        ? e.target.value.toUpperCase()
        : e.target.value;

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const sortedCountries = [...countries].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Generate year options (from current year - 5 to current year + 1)
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 7 }, (_, i) => {
    const year = currentYear - 5 + i;
    return { value: year.toString(), label: year.toString() };
  });

  // Add a function to filter sub-agents by counselor
  const getFilteredSubAgents = () => {
    if (!formData.counselor_id) return [];
    // If user has see_all_subagents permission, show all sub-agents
    if (
      user.is_admin ||
      (user.permissions && user.permissions.includes("see_all_subagents"))
    ) {
      return subAgents;
    }
    // Otherwise, only show sub-agents assigned to the counselor
    return subAgents.filter(
      (agent) => agent.counselor_id.toString() === formData.counselor_id
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" dialogClassName="wide-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? "Edit Student" : "Add New Student"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="text-muted mb-3" style={{ fontSize: "0.875rem" }}>
            Fields marked with <span style={{ color: "red" }}>*</span> are
            required
          </div>

          <div className="row">
            {/* Left Column */}
            <div className="col-md-6 pe-2">
              {/* Personal Information Section */}
              <div style={sectionStyle}>
                <div style={sectionTitleStyle}>Personal Information</div>
                <Form.Group className="mb-3">
                  <RequiredLabel>Student Name</RequiredLabel>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter student name"
                    required
                  />
                </Form.Group>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter student email"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        placeholder="Enter phone number"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              {/* Document Information Section */}
              <div style={sectionStyle}>
                <div style={sectionTitleStyle}>Document Information</div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <RequiredLabel>Travel Document Number</RequiredLabel>
                      <Form.Control
                        type="text"
                        name="travel_doc_no"
                        value={formData.travel_doc_no}
                        onChange={handleChange}
                        placeholder="Enter travel document number"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <RequiredLabel>Nationality</RequiredLabel>
                      <Form.Select
                        name="nationality"
                        value={formData.nationality}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select nationality</option>
                        {sortedCountries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6 ps-2">
              {/* Academic Information Section */}
              <div style={sectionStyle}>
                <div style={sectionTitleStyle}>Academic Information</div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <RequiredLabel>University</RequiredLabel>
                      <Form.Select
                        name="university_id"
                        value={formData.university_id}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select university</option>
                        {universities.map((university) => (
                          <option key={university.id} value={university.id}>
                            {university.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Student Year</Form.Label>
                      <Form.Select
                        name="student_year"
                        value={formData.student_year}
                        onChange={handleChange}
                      >
                        <option value="">Select year</option>
                        {yearOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>

              {/* Management Information Section */}
              <div style={sectionStyle}>
                <div style={sectionTitleStyle}>Management Information</div>
                <div className="row">
                  <div className="col-12">
                    {(user?.is_admin ||
                      (user?.permissions &&
                        user?.permissions.includes("see_all_counselors"))) && (
                      <Form.Group className="mb-3">
                        <RequiredLabel>Counselor</RequiredLabel>
                        <Form.Select
                          name="counselor_id"
                          value={formData.counselor_id}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedSubAgent("");
                            setOtherSubAgentName("");
                          }}
                          required
                        >
                          <option value="">Select counselor</option>
                          {counselors.map((counselor) => (
                            <option
                              key={counselor.id}
                              value={counselor.id.toString()}
                            >
                              {counselor.name}{" "}
                              {counselor.id.toString() === user?.id?.toString()
                                ? "(You)"
                                : ""}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}

                    {formData.counselor_id && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>Sub-agent</Form.Label>
                          <Form.Select
                            value={selectedSubAgent}
                            onChange={(e) => {
                              setSelectedSubAgent(e.target.value);
                              if (e.target.value !== "Other") {
                                setOtherSubAgentName("");
                              }
                            }}
                          >
                            <option value="">Without Sub-agent</option>
                            {getFilteredSubAgents().map((agent) => (
                              <option key={agent.id} value={agent.name}>
                                {agent.name}
                              </option>
                            ))}
                            <option value="Other">Other</option>
                          </Form.Select>
                        </Form.Group>

                        {selectedSubAgent === "Other" && (
                          <Form.Group className="mb-3">
                            <RequiredLabel>Other Sub-agent Name</RequiredLabel>
                            <Form.Control
                              type="text"
                              value={otherSubAgentName}
                              onChange={(e) =>
                                setOtherSubAgentName(e.target.value)
                              }
                              placeholder="Enter sub-agent name"
                              required
                            />
                          </Form.Group>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end gap-2 mt-3">
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Save Changes" : "Add Student"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddStudentModal;

<style jsx>{`
  :global(.wide-modal) {
    max-width: 90%;
    width: 1200px;
  }

  :global(.wide-modal .modal-body) {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }
`}</style>;
