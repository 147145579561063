/**
 * Dashboard component that displays and manages student information
 * Features include student listing, filtering, status updates, and CRUD operations
 * Implements role-based access control for different user permissions
 */

import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Button, Modal, Dropdown, Pagination } from "react-bootstrap";
import AppNavbar from "./Navbar";
import Footer from "./Footer";
import AddStudentModal from "./AddStudentModal";
import SearchAndFilter from "./SearchAndFilter";
import "../styles/Dashboard.css";
import { countries } from "../utils/countries";
import config from "../config";
import { useData } from "../context/DataContext";
import { Skeleton } from "@mui/material";

/**
 * Main Dashboard component
 * @param {Object} user - Current user object containing permissions and role information
 * @param {Function} onLogout - Callback function to handle user logout
 */
const Dashboard = ({ user, onLogout }) => {
  console.log("Dashboard render");

  const { cachedData, updateCache } = useData();
  const [students, setStudents] = useState(cachedData.students || []);
  const [loading, setLoading] = useState(!cachedData.students);
  const [showProgress, setShowProgress] = useState(false);
  const [updateProgress, setUpdateProgress] = useState({
    show: false,
    current: 0,
    total: 0,
    currentStudent: null,
  });
  const [showAddStudent, setShowAddStudent] = useState(false);
  const [selectedStudentProgress, setSelectedStudentProgress] = useState([]);
  const [showEditStudent, setShowEditStudent] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    searchQuery: "",
    nationality: [],
    status: [],
    universities: [],
    counselor: [],
    application_type: [],
    student_year: [],
    has_sub_agent: "",
    sub_agent: [],
    sortBy: "lastUpdated",
  });
  const [showIssues, setShowIssues] = useState(false);
  const [selectedStudentIssues, setSelectedStudentIssues] = useState([]);
  const [showFiles, setShowFiles] = useState(false);
  const [selectedStudentForFiles, setSelectedStudentForFiles] = useState(null);
  const [subAgents, setSubAgents] = useState(cachedData.subAgents || []);
  const [updateResult, setUpdateResult] = useState({
    show: false,
    type: "",
    message: "",
    title: "",
  });
  const [exporting, setExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10);

  // Add data fetching ref
  const dataFetchedRef = useRef({
    students: false,
    universities: false,
    subAgents: false,
  });

  // Add request tracking ref
  const requestTrackingRef = useRef({
    inProgress: false,
  });

  // Permission checks based on user role and permissions
  const hasEditPermission = useMemo(
    () =>
      user &&
      (user.is_admin ||
        (user.permissions && user.permissions.includes("edit_students"))),
    [user]
  );

  const hasAddPermission = useMemo(
    () =>
      user &&
      (user.is_admin ||
        (user.permissions && user.permissions.includes("add_student"))),
    [user]
  );

  // Single useEffect for data loading
  useEffect(() => {
    let isMounted = true;
    const currentRequestRef = requestTrackingRef.current;

    const loadData = async () => {
      // Check if data is already fetched in this session or if a request is in progress
      if (
        currentRequestRef.inProgress ||
        Object.values(dataFetchedRef.current).every(Boolean)
      ) {
        return;
      }

      currentRequestRef.inProgress = true;

      // Set initial states from cache if available
      if (cachedData.students && !dataFetchedRef.current.students) {
        setStudents(cachedData.students);
        setLoading(false);
        dataFetchedRef.current.students = true;
      }
      if (cachedData.subAgents && !dataFetchedRef.current.subAgents) {
        setSubAgents(cachedData.subAgents);
        dataFetchedRef.current.subAgents = true;
      }

      try {
        const fetchPromises = [];

        if (!dataFetchedRef.current.students && !cachedData.students) {
          fetchPromises.push(
            fetch(`${config.API_URL}/students`, {
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }).then(async (response) => {
              if (response.ok) {
                const data = await response.json();
                if (isMounted) {
                  setStudents(data);
                  updateCache("students", data);
                  dataFetchedRef.current.students = true;
                }
              }
            })
          );
        }

        if (
          !dataFetchedRef.current.universities &&
          !cachedData.universities &&
          user.is_admin
        ) {
          fetchPromises.push(
            fetch(`${config.API_URL}/admin/universities`, {
              credentials: "include",
            }).then(async (response) => {
              if (response.ok) {
                const data = await response.json();
                if (isMounted) {
                  updateCache("universities", data);
                  dataFetchedRef.current.universities = true;
                }
              }
            })
          );
        }

        if (!dataFetchedRef.current.subAgents && !cachedData.subAgents) {
          fetchPromises.push(
            fetch(`${config.API_URL}/sub-agents`, {
              credentials: "include",
            }).then(async (response) => {
              if (response.ok) {
                const data = await response.json();
                if (isMounted) {
                  setSubAgents(data);
                  updateCache("subAgents", data);
                  dataFetchedRef.current.subAgents = true;
                }
              }
            })
          );
        }

        if (fetchPromises.length > 0) {
          await Promise.all(fetchPromises);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
      currentRequestRef.inProgress = false;
    };
  }, [user.is_admin, cachedData, updateCache]); // Include dependencies but use refs to prevent re-runs

  /**
   * Converts country code to full country name
   * @param {string} countryCode - Two-letter country code
   * @returns {string} Full country name or original code if not found
   */
  const getCountryName = (countryCode) => {
    if (!countryCode) return "N/A";
    const country = countries.find(
      (c) => c.code.toUpperCase() === countryCode.toUpperCase()
    );
    return country ? country.name : countryCode;
  };

  /**
   * Handles bulk status update for all students
   */
  const handleUpdateStatus = async () => {
    setLoading(true);
    setError(null);
    let updatedStudents = [...students];
    const studentsToUpdate = students.filter(
      (student) =>
        !(
          student.application_status &&
          (student.application_status.toLowerCase() ===
            "application completed" ||
            student.application_status.toLowerCase() ===
              "application closed") &&
          student.percentage === "100%"
        ) && student.is_active
    );

    setUpdateProgress({
      show: true,
      current: 0,
      total: studentsToUpdate.length,
      currentStudent: null,
    });

    try {
      for (let i = 0; i < studentsToUpdate.length; i++) {
        const student = studentsToUpdate[i];
        setUpdateProgress((prev) => ({
          ...prev,
          current: i + 1,
          currentStudent: student.name,
        }));

        try {
          const response = await fetch(
            `${config.API_URL}/check-single-status`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({
                id: student.id,
                travel_doc_no: student.travel_doc_no,
                nationality: student.nationality.toUpperCase(),
              }),
            }
          );

          if (response.ok) {
            const updatedStudent = await response.json();
            if (updatedStudent) {
              // Update the student in our local array
              updatedStudents = updatedStudents.map((s) =>
                s.id === updatedStudent.id ? updatedStudent : s
              );
            }
          } else {
            const errorData = await response.json();
            console.warn(
              `Error updating student ${student.name}:`,
              errorData.error
            );
          }
        } catch (error) {
          console.warn(
            `Network error updating student ${student.name}:`,
            error
          );
        }

        // Update the students state every few students or at the end
        if (i % 5 === 0 || i === studentsToUpdate.length - 1) {
          setStudents(updatedStudents);
          updateCache("students", updatedStudents);
        }
      }

      setError(null);
    } catch (error) {
      console.error("Error updating status:", error);
      setError(
        "Some errors occurred while updating students. Please refresh the page to see the latest changes."
      );
    } finally {
      // Make sure we have the final state
      setStudents(updatedStudents);
      updateCache("students", updatedStudents);
      setLoading(false);
      setUpdateProgress({
        show: false,
        current: 0,
        total: 0,
        currentStudent: null,
      });
    }
  };

  /**
   * Updates filter state based on user input
   * @param {Event} e - Change event from filter inputs
   */
  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === "reset") {
      setFilters(value);
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  /**
   * Shows progress modal for selected student
   * @param {Object} student - Student object containing progress information
   */
  const handleShowProgress = (student) => {
    setSelectedStudentProgress(student.application_progress || []);
    setShowProgress(true);
  };

  /**
   * Format the date and time without timezone conversion
   * @param {string} dateTimeStr - ISO date-time string
   * @param {Object} student - Student object
   * @returns {JSX.Element} Formatted date-time display with hover information
   */
  const formatDateTime = (dateTimeStr, student) => {
    if (!dateTimeStr) return "N/A";

    // Parse the ISO string without timezone conversion
    const date = new Date(dateTimeStr);

    // Format the date without timezone conversion
    const formattedDateTime = date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    // Split the formatted string into date and time parts
    const [dateStr, timeStr] = formattedDateTime.split(", ");

    const tooltipContent = getTooltipContent(student);

    return (
      <div className="date-time-container tooltip-wrapper">
        <div className="date">{dateStr}</div>
        <div className="time">{timeStr}</div>
        {tooltipContent && (
          <div className="custom-tooltip">{tooltipContent}</div>
        )}
      </div>
    );
  };

  /**
   * First, let's create a function to format the tooltip content
   * @param {Object} student - Student object
   * @returns {string} Formatted tooltip content
   */
  const getTooltipContent = (student) => {
    let tooltipContent = [];
    if (student.last_checked) {
      tooltipContent.push(
        `Last checked: ${new Date(student.last_checked).toLocaleString(
          "en-US",
          {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        )}`
      );
    }
    if (student.next_check_time) {
      tooltipContent.push(
        `Next check: ${new Date(student.next_check_time).toLocaleString(
          "en-US",
          {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        )}`
      );
    }
    return tooltipContent.join("\n");
  };

  /**
   * Handles student edit action with permission check
   * @param {Object} student - Student object to be edited
   */
  const handleEditStudent = (student) => {
    if (user.is_admin || student.user_id === user.id) {
      setSelectedStudent(student);
      setShowEditStudent(true);
    } else {
      setError("You don't have permission to edit this student");
    }
  };

  /**
   * Handles the student update after add/edit
   * @param {Object} updatedStudent - The updated or new student data
   * @param {boolean} isEditing - Whether this is an edit or new student
   */
  const handleStudentUpdate = (updatedStudent, isEditing) => {
    let updatedStudents;
    if (isEditing) {
      updatedStudents = students.map((student) =>
        student.id === updatedStudent.id
          ? { ...student, ...updatedStudent }
          : student
      );
    } else {
      updatedStudents = [...students, updatedStudent];
    }
    setStudents(updatedStudents);
    updateCache("students", updatedStudents);

    // Also update adminStudents cache if it exists
    if (cachedData.adminStudents) {
      let updatedAdminStudents;
      if (isEditing) {
        updatedAdminStudents = cachedData.adminStudents.map((student) =>
          student.id === updatedStudent.id
            ? { ...student, ...updatedStudent }
            : student
        );
      } else {
        updatedAdminStudents = [...cachedData.adminStudents, updatedStudent];
      }
      updateCache("adminStudents", updatedAdminStudents);
    }
  };

  /**
   * Memoized filtered and sorted students list
   * Applies search, nationality, and status filters
   * Sorts based on selected sort criteria
   */
  const filteredStudents = useMemo(() => {
    return students
      .filter((student) => {
        // Filter by user permissions
        if (
          !user.is_admin &&
          !user.permissions?.includes("view_all_students") &&
          student.user_id !== user.id
        ) {
          return false;
        }

        // Apply search filter
        const matchesSearch = filters.searchQuery
          ? student.name
              ?.toLowerCase()
              .includes(filters.searchQuery.toLowerCase()) ||
            student.travel_doc_no
              ?.toLowerCase()
              .includes(filters.searchQuery.toLowerCase())
          : true;

        // Apply nationality filter
        const matchesNationality =
          filters.nationality.length === 0
            ? true
            : filters.nationality.includes(student.nationality.toUpperCase());

        // Apply university filter
        const matchesUniversity =
          filters.universities.length === 0
            ? true
            : filters.universities.includes(student.university);

        // Apply status filter
        const matchesStatus =
          filters.status.length === 0
            ? true
            : filters.status.includes(student.application_status);

        // Apply counselor filter
        const matchesCounselor =
          filters.counselor.length === 0
            ? true
            : filters.counselor.includes(student.user_name);

        // Apply application type filter
        const matchesApplicationType =
          filters.application_type.length === 0
            ? true
            : filters.application_type.includes(student.application_type);

        // Apply student year filter
        const matchesStudentYear =
          filters.student_year.length === 0
            ? true
            : filters.student_year.includes(student.student_year?.toString());

        // Apply has sub-agent filter
        const matchesHasSubAgent =
          filters.has_sub_agent === ""
            ? true
            : filters.has_sub_agent === "yes"
            ? !!student.sub_agent_name
            : !student.sub_agent_name;

        // Apply sub-agent filter
        const matchesSubAgent =
          filters.sub_agent.length === 0
            ? true
            : filters.sub_agent.includes(student.sub_agent_name);

        return (
          matchesSearch &&
          matchesNationality &&
          matchesStatus &&
          matchesUniversity &&
          matchesCounselor &&
          matchesApplicationType &&
          matchesStudentYear &&
          matchesHasSubAgent &&
          matchesSubAgent
        );
      })
      .sort((a, b) => {
        // Check if applications are complete and was viewed more recently than it was last updated
        const aIsComplete =
          a.percentage === "100%" &&
          new Date(a.last_viewed) > new Date(a.last_updated);
        const bIsComplete =
          b.percentage === "100%" &&
          new Date(b.last_viewed) > new Date(b.last_updated);

        // If one is complete and the other isn't, push complete one to bottom
        if (aIsComplete && !bIsComplete) return 1;
        if (!aIsComplete && bIsComplete) return -1;

        // First sort by update status (unviewed updates at top)
        const aLastUpdated = a.last_updated ? new Date(a.last_updated) : null;
        const aLastViewed = a.last_viewed ? new Date(a.last_viewed) : null;
        const bLastUpdated = b.last_updated ? new Date(b.last_updated) : null;
        const bLastViewed = b.last_viewed ? new Date(b.last_viewed) : null;

        const aHasUpdate =
          aLastUpdated &&
          (!aLastViewed || aLastUpdated.getTime() > aLastViewed.getTime());
        const bHasUpdate =
          bLastUpdated &&
          (!bLastViewed || bLastUpdated.getTime() > bLastViewed.getTime());

        if (aHasUpdate && !bHasUpdate) return -1;
        if (!aHasUpdate && bHasUpdate) return 1;

        // Then apply the selected sort criteria
        switch (filters.sortBy) {
          case "name":
            return (a.name || "").localeCompare(b.name || "");
          case "lastUpdated":
            return (
              (bLastUpdated?.getTime() || 0) - (aLastUpdated?.getTime() || 0)
            );
          case "percentage":
            return (
              (parseInt(b.percentage) || 0) - (parseInt(a.percentage) || 0)
            );
          case "studentYear":
            const aYear = parseInt(a.student_year) || 0;
            const bYear = parseInt(b.student_year) || 0;
            return bYear - aYear; // Sort in descending order (newest years first)
          default:
            return 0;
        }
      });
  }, [students, filters, user]);

  // Add this before the return statement in Dashboard component
  const uniqueUniversities = useMemo(() => {
    return [...new Set(students.map((s) => s.university))]
      .filter(Boolean)
      .sort();
  }, [students]);

  const uniqueCounselors = useMemo(() => {
    return [...new Set(students.map((s) => s.user_name))]
      .filter(Boolean)
      .sort();
  }, [students]);

  /**
   * Handles single student status update
   * @param {Object} student - Student object to update
   */
  const handleSingleStudentUpdate = async (student) => {
    try {
      setUpdateProgress({
        show: true,
        current: 0,
        total: 1,
        currentStudent: student.name,
      });

      const response = await fetch(`${config.API_URL}/check-single-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          id: student.id,
          travel_doc_no: student.travel_doc_no,
          nationality: student.nationality.toUpperCase(),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.message === "Student status already complete") {
          setUpdateResult({
            show: true,
            type: "info",
            title: "No Update Needed",
            message: `${student.name}'s application is already complete (100%).`,
          });
        } else if (data.message === "Student is inactive") {
          setUpdateResult({
            show: true,
            type: "info",
            title: "Student Inactive",
            message: `${student.name} is inactive. No update performed.`,
          });
        } else {
          // Check if percentage changed
          const oldPercentage = student.percentage || "0%";
          const newPercentage = data.percentage || "0%";
          const percentageChanged = oldPercentage !== newPercentage;

          // Update the student in our local array
          const updatedStudents = students.map((s) =>
            s.id === data.id ? data : s
          );
          setStudents(updatedStudents);
          updateCache("students", updatedStudents);

          setUpdateResult({
            show: true,
            type: percentageChanged ? "success" : "info",
            title: percentageChanged ? "Status Updated" : "No Change in Status",
            message: percentageChanged
              ? `Successfully updated ${student.name}'s status. Progress changed from ${oldPercentage} to ${newPercentage}.`
              : `${student.name}'s status check complete. Progress remained at ${newPercentage}.`,
          });
        }
      } else {
        setUpdateResult({
          show: true,
          type: "error",
          title: "Update Failed",
          message: data.error || `Failed to update ${student.name}'s status.`,
        });
      }
    } catch (error) {
      console.error("Error updating student status:", error);
      setUpdateResult({
        show: true,
        type: "error",
        title: "Network Error",
        message: `Network error while updating ${student.name}'s status.`,
      });
    } finally {
      setUpdateProgress({
        show: false,
        current: 0,
        total: 0,
        currentStudent: null,
      });
    }
  };

  // Add this near your other modal components
  const ResultModal = () => (
    <Modal
      show={updateResult.show}
      onHide={() => setUpdateResult((prev) => ({ ...prev, show: false }))}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{updateResult.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={`alert alert-${
            updateResult.type === "success"
              ? "success"
              : updateResult.type === "info"
              ? "info"
              : "danger"
          } mb-0`}
        >
          {updateResult.message}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setUpdateResult((prev) => ({ ...prev, show: false }))}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  // Add the export function
  const handleExport = async () => {
    try {
      setExporting(true);
      const response = await fetch(`${config.API_URL}/students/export`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          students: filteredStudents,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to export students");
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.download = "students_export.xlsx";

      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting students:", error);
      setError({
        type: "error",
        message: error.message || "Failed to export students",
      });
    } finally {
      setExporting(false);
    }
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const tableData = filteredStudents;

    // Function to convert country code to name
    const getCountryName = (code) => {
      const country = countries.find((c) => c.code === code);
      return country ? country.name : code;
    };

    // Check if all counselors are the same
    const uniqueCounselors = new Set(
      tableData.map((student) => student.user_name)
    );
    const showCounselorColumn = uniqueCounselors.size > 1;

    // Create a map to check for empty columns
    const columnsWithData = {
      email: false,
      phone_number: false,
      travel_doc_no: false,
      nationality: false,
      university: false,
      application_status: false,
      percentage: false,
      application_type: false,
      package: false,
      user_name: showCounselorColumn, // Only show counselor column if there are different counselors
    };

    // Check which columns have data
    tableData.forEach((student) => {
      Object.keys(columnsWithData).forEach((key) => {
        if (student[key] && key !== "user_name") {
          // Skip checking user_name as it's controlled by showCounselorColumn
          columnsWithData[key] = true;
        }
      });
    });

    const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Student List</title>
          <style>
            body { 
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            table { 
              width: 100%; 
              border-collapse: collapse; 
              margin-top: 20px;
            }
            th, td { 
              border: 1px solid #ddd; 
              padding: 8px; 
              text-align: left; 
              font-size: 12px;
            }
            th { 
              background-color: #1a73e8; 
              color: white; 
            }
            tr:nth-child(even) { background-color: #f9f9fa; }
            .header { 
              text-align: center; 
              margin-bottom: 20px;
            }
            .meta-info {
              text-align: left;
              margin-bottom: 15px;
              font-size: 12px;
            }
            @media print {
              .header { margin-bottom: 30px; }
              table { page-break-inside: auto; }
              tr { page-break-inside: avoid; }
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h2>Student List</h2>
          </div>
          <div class="meta-info">
            <p>Generated by: ${user.name}</p>
            <p>Total Records: ${tableData.length}</p>
            <p>Generated on: ${new Date().toLocaleString()}</p>
            ${
              !showCounselorColumn && uniqueCounselors.size === 1
                ? `<p>Counselor: ${tableData[0].user_name || "N/A"}</p>`
                : ""
            }
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                ${columnsWithData.email ? "<th>Email</th>" : ""}
                ${columnsWithData.phone_number ? "<th>Phone</th>" : ""}
                ${columnsWithData.travel_doc_no ? "<th>Travel Doc</th>" : ""}
                ${columnsWithData.nationality ? "<th>Nationality</th>" : ""}
                ${columnsWithData.university ? "<th>University</th>" : ""}
                ${columnsWithData.application_status ? "<th>Status</th>" : ""}
                ${columnsWithData.percentage ? "<th>Progress</th>" : ""}
                ${columnsWithData.application_type ? "<th>Type</th>" : ""}
                ${columnsWithData.user_name ? "<th>Counselor</th>" : ""}
              </tr>
            </thead>
            <tbody>
              ${tableData
                .map(
                  (student) => `
                <tr>
                  <td>${student.name || ""}</td>
                  ${
                    columnsWithData.email
                      ? `<td>${student.email || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.phone_number
                      ? `<td>${student.phone_number || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.travel_doc_no
                      ? `<td>${student.travel_doc_no || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.nationality
                      ? `<td>${getCountryName(student.nationality) || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.university
                      ? `<td>${student.university || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.application_status
                      ? `<td>${student.application_status || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.percentage
                      ? `<td>${student.percentage || "0"}%</td>`
                      : ""
                  }
                  ${
                    columnsWithData.application_type
                      ? `<td>${student.application_type || ""}</td>`
                      : ""
                  }
                  ${
                    columnsWithData.user_name
                      ? `<td>${student.user_name || ""}</td>`
                      : ""
                  }
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </body>
      </html>
    `;

    printWindow.document.write(html);
    printWindow.document.close();
    printWindow.onload = function () {
      printWindow.print();
      printWindow.onafterprint = function () {
        printWindow.close();
      };
    };
  };

  // Add pagination calculation
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );
  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  // Add pagination handler
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Add pagination controls component
  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    let items = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust start page if we're near the end
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    // Add first page and ellipsis if needed
    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="ellipsis1" disabled />);
      }
    }

    // Add page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Add last page and ellipsis if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="ellipsis2" disabled />);
      }
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return (
      <div className="d-flex justify-content-center mt-3 mb-3">
        <Pagination>
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {items}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    );
  };

  // Remove the payment info column from the table headers
  const tableHeaders = [
    { key: "name", label: "Name" },
    { key: "university", label: "University" },
    { key: "travel_doc_no", label: "Passport No." },
    ...(user.is_admin || user.permissions?.includes("view_all_students")
      ? [{ key: "user_name", label: "Counselor" }]
      : []),
    { key: "application_status", label: "Status" },
    { key: "percentage", label: "Progress" },
    { key: "last_updated", label: "Last Updated" },
    { key: "student_year", label: "Student Year" },
    { key: "actions", label: "Actions" },
  ];

  return (
    <>
      <AppNavbar user={user} onLogout={onLogout} />
      <div
        className="dashboard-container"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Add Progress Modal */}
        <Modal
          show={updateProgress.show}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>
              {exporting ? "Exporting Students" : "Updating Student Status"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mb-3">
              <div className="mb-2">
                {exporting
                  ? "Preparing export file..."
                  : `Processing: ${
                      updateProgress.currentStudent || "Preparing..."
                    }`}
              </div>
              {!exporting && (
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${
                        (updateProgress.current / updateProgress.total) * 100
                      }%`,
                    }}
                    aria-valuenow={updateProgress.current}
                    aria-valuemin="0"
                    aria-valuemax={updateProgress.total}
                  >
                    {updateProgress.current} / {updateProgress.total}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {/* Add Result Modal */}
        <ResultModal />

        <div
          className="table-container"
          style={{ flex: 1, minHeight: "200px" }}
        >
          {/* Dashboard header with title and action buttons */}
          <div className="table-header" style={{ minHeight: "48px" }}>
            <h3>
              Student List{" "}
              {!user.is_admin &&
                !user.permissions?.includes("view_all_students") &&
                "(Your Students)"}
            </h3>
            {error && (
              <div
                className={`alert alert-${
                  error.type === "success"
                    ? "success"
                    : error.type === "info"
                    ? "info"
                    : "danger"
                } alert-dismissible fade show mb-3`}
                role="alert"
                style={{ minHeight: "60px" }}
              >
                {error.message}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setError(null)}
                  aria-label="Close"
                ></button>
              </div>
            )}
            <div className="button-group" style={{ minHeight: "38px" }}>
              {/* Print button */}
              <Button
                variant="secondary"
                className="print-btn me-2"
                onClick={handlePrint}
                disabled={loading}
              >
                <i className="fas fa-print me-2"></i>
                Print Table
              </Button>
              {/* Export button */}
              <Button
                variant="info"
                className="export-btn me-2"
                onClick={handleExport}
                disabled={exporting || loading}
              >
                <i className="fas fa-file-export me-2"></i>
                {exporting ? "Exporting..." : "Export to Excel"}
              </Button>
              {/* Status update button */}
              <Button
                variant="success"
                className="update-status-btn me-2"
                onClick={handleUpdateStatus}
                disabled={loading}
              >
                <i className="fas fa-sync-alt me-2"></i>
                {loading ? "Updating..." : "Update Status"}
              </Button>
              {/* Add student button - only shown if user has permission */}
              {hasAddPermission && (
                <Button
                  variant="primary"
                  className="add-student-btn"
                  onClick={() => setShowAddStudent(true)}
                >
                  <i className="fas fa-plus me-2"></i>
                  Add New Student
                </Button>
              )}
            </div>
          </div>

          {/* Add record count display with pagination info */}
          <div className="record-count mb-3" style={{ minHeight: "24px" }}>
            {loading ? (
              <Skeleton width={200} height={24} />
            ) : (
              <span>
                Showing {indexOfFirstStudent + 1} to{" "}
                {Math.min(indexOfLastStudent, filteredStudents.length)} of{" "}
                {filteredStudents.length} records
                {filteredStudents.length !== students.length &&
                  ` (filtered from ${students.length} total records)`}
              </span>
            )}
          </div>

          {/* Search and filter component */}
          <div style={{ minHeight: "120px" }}>
            <SearchAndFilter
              filters={filters}
              onFilterChange={handleFilterChange}
              students={students}
              universities={uniqueUniversities}
              counselors={
                user.is_admin || user.permissions?.includes("view_all_students")
                  ? uniqueCounselors
                  : []
              }
              user={user}
              subAgents={subAgents}
            />
          </div>

          {/* Students data table */}
          {loading ? (
            <div style={{ minHeight: "400px" }}>
              {[...Array(5)].map((_, index) => (
                <div key={index} style={{ padding: "8px 0" }}>
                  <Skeleton height={60} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <Table hover className="modern-table">
                <thead>
                  <tr style={{ height: "48px" }}>
                    {tableHeaders.map((header) => (
                      <th key={header.key}>{header.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentStudents.map((student, index, array) => {
                    // Parse dates and ensure they're in the same format
                    const lastUpdated = student.last_updated
                      ? new Date(student.last_updated)
                      : null;
                    const lastViewed = student.last_viewed
                      ? new Date(student.last_viewed)
                      : null;

                    // Compare timestamps
                    const hasUpdate =
                      lastUpdated &&
                      (!lastViewed ||
                        lastUpdated.getTime() > lastViewed.getTime());
                    const prevStudent = index > 0 ? array[index - 1] : null;
                    const prevHasUpdate =
                      prevStudent &&
                      prevStudent.last_updated &&
                      (!prevStudent.last_viewed ||
                        new Date(prevStudent.last_updated).getTime() >
                          new Date(prevStudent.last_viewed).getTime());

                    // Add section header when transitioning from updated to non-updated students
                    const showSeparator = hasUpdate !== prevHasUpdate;

                    // Common select props for all dropdowns
                    const commonSelectProps = {
                      styles: {
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      },
                    };

                    return (
                      <React.Fragment key={index}>
                        {showSeparator && (
                          <tr className="section-header">
                            <td
                              colSpan={10}
                              className={`section-header-cell ${
                                hasUpdate ? "has-updates" : ""
                              }`}
                            >
                              <i
                                className={`fas ${
                                  hasUpdate
                                    ? "fa-bell text-primary"
                                    : "fa-check text-muted"
                                }`}
                              ></i>
                              {hasUpdate
                                ? "Recently Updated"
                                : " No Recent Updates"}
                            </td>
                          </tr>
                        )}
                        <tr
                          key={index}
                          style={{ minHeight: "60px" }}
                          className={hasUpdate ? "unviewed-update" : ""}
                        >
                          <td data-label="Name">
                            <div className="d-flex align-items-center gap-2">
                              {student.name}
                              <span className="nationality-badge">
                                {getCountryName(student.nationality)}
                              </span>
                            </div>
                          </td>
                          <td data-label="University">{student.university}</td>
                          <td data-label="Passport No.">
                            {student.travel_doc_no}
                          </td>
                          {(user.is_admin ||
                            user.permissions?.includes(
                              "view_all_students"
                            )) && (
                            <td data-label="Counselor" className="counselor-cell">
                              <span className="counselor-name">{student.user_name}</span>
                              {student.sub_agent_name && (
                                <span
                                  className={`sub-agent-name ${
                                    subAgents.some(
                                      (sa) =>
                                        sa.name.toLowerCase() ===
                                        student.sub_agent_name.toLowerCase()
                                    )
                                      ? "existing-sub-agent"
                                      : "other-sub-agent"
                                  }`}
                                >
                                  ({student.sub_agent_name})
                                </span>
                              )}
                            </td>
                          )}
                          <td data-label="Status">
                            <span
                              className={`badge ${
                                student.percentage === "100%"
                                  ? "bg-success-dark"
                                  : student.application_status ===
                                    "Application completed"
                                  ? "bg-success"
                                  : student.application_status ===
                                    "VAL Approved and ready for download"
                                  ? "bg-success"
                                  : student.application_status ===
                                    "Pending additional document(s) from the institution"
                                  ? "bg-warning"
                                  : student.application_status ===
                                    "In Progress with EMGS"
                                  ? "bg-info"
                                  : student.application_status ===
                                    "VAL Rejected"
                                  ? "bg-danger"
                                  : "bg-secondary"
                              }`}
                            >
                              {student.application_status || "Unknown"}
                            </span>
                          </td>
                          <td data-label="Progress">
                            <div className="mobile-progress-container">
                              <div className="progress-text">
                                {student.percentage || "0%"}
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: student.percentage
                                      ? student.percentage
                                      : "0%",
                                    backgroundColor:
                                      student.application_status ===
                                      "VAL Rejected"
                                        ? "#dc3545"
                                        : student.application_issues &&
                                          student.application_issues.length > 0
                                        ? "#F5A724"
                                        : "#098136",
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td data-label="Last Updated">
                            {student.last_updated
                              ? formatDateTime(student.last_updated, student)
                              : "N/A"}
                          </td>
                          <td data-label="Student Year">
                            {student.student_year || "N/A"}
                          </td>
                          <td data-label="Actions">
                            <div className="action-buttons">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="secondary"
                                  size="sm"
                                  id={`action-dropdown-${student.id}`}
                                  {...commonSelectProps}
                                >
                                  Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end">
                                  {hasEditPermission && (
                                    <Dropdown.Item
                                      onClick={() => handleEditStudent(student)}
                                    >
                                      <i className="fas fa-edit me-2"></i>
                                      Edit Student
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    onClick={() => handleShowProgress(student)}
                                  >
                                    <i className="fas fa-history me-2"></i>
                                    View Progress
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setSelectedStudentIssues(
                                        student.application_issues || []
                                      );
                                      setShowIssues(true);
                                    }}
                                  >
                                    <i className="fas fa-exclamation-circle me-2"></i>
                                    View Issues
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setSelectedStudentForFiles(student);
                                      setShowFiles(true);
                                    }}
                                  >
                                    <i className="fas fa-file me-2"></i>
                                    View Files
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleSingleStudentUpdate(student)
                                    }
                                  >
                                    <i className="fas fa-sync-alt me-2"></i>
                                    Update Status
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationControls />
            </>
          )}
        </div>

        <Modal
          show={showProgress}
          onHide={() => setShowProgress(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Application Progress History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {selectedStudentProgress.map((progress, index) => (
                  <tr key={index}>
                    <td>{progress.date}</td>
                    <td>{progress.status}</td>
                    <td>{progress.remark}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        <AddStudentModal
          show={showAddStudent}
          onHide={() => setShowAddStudent(false)}
          onStudentAdded={handleStudentUpdate}
          user={user}
          subAgents={subAgents}
        />

        <AddStudentModal
          show={showEditStudent}
          onHide={() => {
            setShowEditStudent(false);
            setSelectedStudent(null);
          }}
          onStudentAdded={handleStudentUpdate}
          studentToEdit={selectedStudent}
          isEditing={true}
          user={user}
          subAgents={subAgents}
        />

        <IssuesModal
          show={showIssues}
          onHide={() => setShowIssues(false)}
          issues={selectedStudentIssues}
        />

        <StudentFilesModal
          show={showFiles}
          onHide={() => {
            setShowFiles(false);
            setSelectedStudentForFiles(null);
          }}
          student={selectedStudentForFiles}
        />
      </div>
      <Footer />
    </>
  );
};

/**
 * Modal component for displaying student issues
 * @param {Object} props - Component props
 * @param {boolean} props.show - Controls modal visibility
 * @param {Function} props.onHide - Callback to close modal
 * @param {Array} props.issues - Array of student issues
 */
const IssuesModal = ({ show, onHide, issues = [] }) => {
  const [expandedIssues, setExpandedIssues] = useState({});

  const toggleIssue = (index) => {
    setExpandedIssues((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Function to get the latest status for an issue
  const getLatestStatus = (statuses) => {
    if (!statuses || statuses.length === 0) return null;
    return statuses[0];
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Application Issues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(issues) && issues.length > 0 ? (
          issues.map((issue, index) => {
            const latestStatus = getLatestStatus(issue.Statuses);
            return (
              <div key={index} className="issue-card mb-4 p-3 border rounded">
                <div
                  className="issue-header d-flex justify-content-between align-items-start"
                  onClick={() => toggleIssue(index)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="issue-header-content">
                    <h5 className="mb-1">
                      {issue["No."]} {issue.Issue}
                    </h5>
                    {latestStatus && (
                      <>
                        <div className="latest-status mb-2">
                          <span
                            className={`badge ${
                              latestStatus.Status === "Open"
                                ? "bg-danger"
                                : latestStatus.Status === "Pending"
                                ? "bg-warning"
                                : "bg-success"
                            }`}
                          >
                            {latestStatus.Status}
                          </span>
                          <small className="text-muted ms-2">
                            {latestStatus["Updated At"]}
                          </small>
                        </div>
                        <p className="latest-comment mb-0">
                          {latestStatus.Comment}
                        </p>
                      </>
                    )}
                  </div>
                  <span className="collapse-icon">
                    <i
                      className={`fas fa-chevron-down ${
                        expandedIssues[index] ? "rotated" : ""
                      }`}
                    ></i>
                  </span>
                </div>
                <div
                  className={`issue-content mt-3 ${
                    expandedIssues[index] ? "show" : ""
                  }`}
                >
                  {issue.Statuses.map((status, statusIndex) => (
                    <div
                      key={statusIndex}
                      className="status-update mb-3 ps-3 border-start"
                    >
                      <div className="d-flex justify-content-between align-items-start mb-2">
                        <span
                          className={`badge ${
                            status.Status === "Open"
                              ? "bg-danger"
                              : status.Status === "Pending"
                              ? "bg-warning"
                              : "bg-success"
                          }`}
                        >
                          {status.Status}
                        </span>
                        <small className="text-muted">
                          {status["Updated At"]}
                        </small>
                      </div>
                      <p className="mb-0" style={{ whiteSpace: "pre-line" }}>
                        {status.Comment}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center text-muted">No issues found</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

const StudentFilesModal = ({ show, onHide, student }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { cachedData, updateCache } = useData();

  useEffect(() => {
    const fetchFiles = async () => {
      if (!student) return;

      // Check cache first
      if (cachedData.studentFiles[student.id]) {
        setFiles(cachedData.studentFiles[student.id]);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${config.API_URL}/students/${student.id}/files`,
          {
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFiles(data);
          // Update cache
          updateCache("studentFiles", {
            ...cachedData.studentFiles,
            [student.id]: data,
          });
        } else {
          const errorData = await response.json();
          setError(errorData.error || "Failed to fetch files");
        }
      } catch (error) {
        setError("Network error while fetching files");
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchFiles();
    }
  }, [show, student, cachedData.studentFiles, updateCache]);

  const handleDownload = async (filename) => {
    try {
      window.open(
        `${config.API_URL}/students/${student.id}/files/${filename}`,
        "_blank"
      );
    } catch (error) {
      setError("Error downloading file");
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Student Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <div className="alert alert-danger">{error}</div>
        ) : files.length === 0 ? (
          <p className="text-center text-muted">No files available</p>
        ) : (
          <div className="list-group">
            {files.map((file, index) => (
              <div
                key={index}
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              >
                <div>
                  <h6 className="mb-1">
                    {file.type === "emgs_letter"
                      ? "EMGS Approval Letter"
                      : "eVAL"}
                  </h6>
                  <small className="text-muted">{file.filename}</small>
                </div>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleDownload(file.filename)}
                >
                  <i className="fas fa-download me-2"></i>
                  Download
                </Button>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Dashboard;
