import React, { createContext, useState, useContext } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [cachedData, setCachedData] = useState({
    users: null,
    students: null,
    adminStudents: null,
    universities: null,
    settings: null,
    studentFiles: {},
    analytics: null,
    lastImport: null,
    svgAssets: {
      dropdownArrow:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e",
      radioCircle:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e",
    },
  });

  const updateCache = (key, data) => {
    setCachedData((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  const clearCache = () => {
    setCachedData({
      users: null,
      students: null,
      adminStudents: null,
      universities: null,
      settings: null,
      studentFiles: {},
      analytics: null,
      lastImport: null,
      svgAssets: {
        dropdownArrow:
          "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e",
        radioCircle:
          "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e",
      },
    });
  };

  return (
    <DataContext.Provider value={{ cachedData, updateCache, clearCache }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};

export default DataContext;
