import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { countries } from "../utils/countries";
import Select from "react-select";

// Common select styles for all dropdowns
const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

const SearchAndFilter = ({
  filters,
  onFilterChange,
  students,
  universities,
  counselors,
  user,
  subAgents,
}) => {
  // Get unique nationalities from current students
  const existingNationalities = [
    ...new Set(students.map((student) => student.nationality)),
  ];

  // Get country details for existing nationalities and sort by name
  const availableCountries = countries
    .filter((country) =>
      existingNationalities.includes(country.code.toUpperCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  // Get unique statuses from current students
  const availableStatuses = [
    ...new Set(
      students
        .map((student) => student.application_status)
        .filter((status) => status)
    ),
  ].sort();

  // Get unique application types from current students
  const availableApplicationTypes = [
    ...new Set(
      students.map((student) => student.application_type).filter((type) => type)
    ),
  ].sort();

  // Get unique invoice statuses from current students
  const availableInvoiceStatuses = [
    ...new Set(
      students
        .map((student) => student.invoice_status)
        .filter((status) => status)
    ),
  ].sort();

  const hasInvoiceAccess =
    user &&
    (user.is_admin ||
      (user.permissions && user.permissions.includes("invoice_access")));

  // Add package access check
  const hasPackageAccess =
    user &&
    (user.is_admin ||
      (user.permissions && user.permissions.includes("manage_packages")));

  // Add package options
  const packageOptions = [
    { value: "free", label: "Free" },
    { value: "100$", label: "100$" },
    { value: "300$", label: "300$" },
    { value: "500$", label: "500$" },
  ];

  // Handle multi-select change
  const handleMultiSelectChange = (name) => (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    onFilterChange({
      target: {
        name,
        value: selectedValues,
      },
    });
  };

  // Create options for react-select
  const createOptions = (items) =>
    items.map((item) => ({
      value: item,
      label: item,
    }));

  // Create options for each filter
  const countryOptions = availableCountries.map((country) => ({
    value: country.code.toUpperCase(),
    label: country.name,
  }));

  const statusOptions = createOptions(availableStatuses);
  const applicationTypeOptions = createOptions(availableApplicationTypes);
  const invoiceStatusOptions = availableInvoiceStatuses.map((status) => ({
    value: status,
    label: status.charAt(0).toUpperCase() + status.slice(1),
  }));
  const counselorOptions = createOptions(counselors);
  const universityOptions = createOptions(universities);
  const sortOptions = [
    { value: "name", label: "Sort by Name" },
    { value: "lastUpdated", label: "Sort by Last Updated" },
    { value: "percentage", label: "Sort by Progress" },
    { value: "studentYear", label: "Sort by Student Year" },
  ];

  // Get unique student years from current students
  const availableStudentYears = [
    ...new Set(
      students.map((student) => student.student_year).filter((year) => year)
    ),
  ].sort((a, b) => b - a); // Sort years in descending order

  const studentYearOptions = availableStudentYears.map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));

  // Initialize filters as arrays if they're not already
  const getSelectedOptions = (options, filterValue) => {
    if (!filterValue) return [];
    const values = Array.isArray(filterValue) ? filterValue : [filterValue];
    return options.filter((option) => values.includes(option.value));
  };

  // Add reset filters handler
  const handleResetFilters = () => {
    onFilterChange({
      target: {
        name: "reset",
        value: {
          searchQuery: "",
          nationality: [],
          status: [],
          universities: [],
          counselor: [],
          invoice_status: [],
          application_type: [],
          package: [],
          student_year: [],
          has_sub_agent: "",
          sub_agent: [],
          sortBy: "lastUpdated",
        },
      },
    });
  };

  // Add to the existing options at the top
  const hasSubAgentOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  // Update the getSubAgentCounts function to return formatted options
  const getSubAgentOptions = (students) => {
    // Filter students based on selected counselors first
    const filteredStudents = students.filter((student) => {
      if (filters.counselor.length === 0) return true;
      return filters.counselor.includes(student.user_name);
    });

    const counts = {};
    filteredStudents.forEach((student) => {
      if (student.sub_agent_name) {
        counts[student.sub_agent_name] =
          (counts[student.sub_agent_name] || 0) + 1;
      }
    });

    return Object.entries(counts)
      .sort(([, countA], [, countB]) => countB - countA) // Sort by count in descending order
      .map(([agent, count]) => {
        const isExistingAgent = subAgents.some(
          (sa) => sa.name.toLowerCase() === agent.toLowerCase()
        );
        return {
          value: agent,
          label: `${agent} (${count} students)`,
          className: isExistingAgent
            ? "existing-agent-option"
            : "other-agent-option",
        };
      });
  };

  // Common props for all Select components
  const commonSelectProps = {
    styles: selectStyles,
    menuPortalTarget: document.body,
    menuPosition: "fixed",
  };

  // Add custom styles for the sub-agent select
  const subAgentSelectStyles = {
    ...selectStyles,
    option: (base, state) => ({
      ...base,
      color: state.isSelected
        ? "white"
        : state.data.className === "existing-agent-option"
        ? "#1a73e8"
        : "#666",
      backgroundColor: state.isSelected ? "#1a73e8" : base.backgroundColor,
      "&:hover": {
        backgroundColor: state.isSelected ? "#1a73e8" : "#f8f9fa",
      },
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: "#f8f9fa",
      border: "1px solid #e0e0e0",
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color:
        state.data.className === "existing-agent-option" ? "#1a73e8" : "#666",
    }),
    singleValue: (base, state) => ({
      ...base,
      color:
        state.data.className === "existing-agent-option" ? "#1a73e8" : "#666",
    }),
  };

  return (
    <div className="search-filter-container mb-4">
      <Row className="g-3">
        {/* Search and Basic Info Filters */}
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search by name or document number"
              name="searchQuery"
              value={filters.searchQuery}
              onChange={onFilterChange}
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              isMulti
              name="nationality"
              options={countryOptions}
              value={getSelectedOptions(countryOptions, filters.nationality)}
              onChange={handleMultiSelectChange("nationality")}
              placeholder="All Nationalities"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              isMulti
              name="status"
              options={statusOptions}
              value={getSelectedOptions(statusOptions, filters.status)}
              onChange={handleMultiSelectChange("status")}
              placeholder="All Status"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              name="sortBy"
              options={sortOptions}
              value={sortOptions.find(
                (option) => option.value === filters.sortBy
              )}
              onChange={(selected) =>
                onFilterChange({
                  target: { name: "sortBy", value: selected.value },
                })
              }
              placeholder="Sort by"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              isMulti
              name="universities"
              options={universityOptions}
              value={getSelectedOptions(
                universityOptions,
                filters.universities
              )}
              onChange={handleMultiSelectChange("universities")}
              placeholder="Select Universities"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>

        {(user.is_admin || user.permissions?.includes("view_all_students")) && (
          <Col md={3}>
            <Form.Group>
              <Select
                {...commonSelectProps}
                isMulti
                name="counselor"
                options={counselorOptions}
                value={getSelectedOptions(counselorOptions, filters.counselor)}
                onChange={handleMultiSelectChange("counselor")}
                placeholder="All Counselors"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Col>
        )}
        <Col md={2}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              isMulti
              name="application_type"
              options={applicationTypeOptions}
              value={getSelectedOptions(
                applicationTypeOptions,
                filters.application_type
              )}
              onChange={handleMultiSelectChange("application_type")}
              placeholder="All Application Types"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              isMulti
              name="student_year"
              options={studentYearOptions}
              value={getSelectedOptions(
                studentYearOptions,
                filters.student_year
              )}
              onChange={handleMultiSelectChange("student_year")}
              placeholder="All Years"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>

        {hasInvoiceAccess && (
          <Col md={2}>
            <Form.Group>
              <Select
                {...commonSelectProps}
                isMulti
                name="invoice_status"
                options={invoiceStatusOptions}
                value={getSelectedOptions(
                  invoiceStatusOptions,
                  filters.invoice_status
                )}
                onChange={handleMultiSelectChange("invoice_status")}
                placeholder="All Invoice Status"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Col>
        )}
        {hasPackageAccess && (
          <Col md={2}>
            <Form.Group>
              <Select
                {...commonSelectProps}
                isMulti
                name="package"
                options={packageOptions}
                value={getSelectedOptions(packageOptions, filters.package)}
                onChange={handleMultiSelectChange("package")}
                placeholder="All Packages"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Col>
        )}
        <Col md={2}>
          <Form.Group>
            <Select
              {...commonSelectProps}
              name="has_sub_agent"
              options={hasSubAgentOptions}
              value={
                filters.has_sub_agent
                  ? hasSubAgentOptions.find(
                      (option) => option.value === filters.has_sub_agent
                    )
                  : null
              }
              onChange={(selected) => {
                onFilterChange({
                  target: {
                    name: "has_sub_agent",
                    value: selected ? selected.value : "",
                  },
                });
                if (!selected || selected.value !== "yes") {
                  onFilterChange({
                    target: {
                      name: "sub_agent",
                      value: [],
                    },
                  });
                }
              }}
              isClearable
              placeholder="Has Sub-agent"
              className="basic-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        {filters.has_sub_agent === "yes" && (
          <Col md={3}>
            <Form.Group>
              <Select
                {...commonSelectProps}
                styles={subAgentSelectStyles}
                isMulti
                name="sub_agent"
                options={getSubAgentOptions(students)}
                value={getSelectedOptions(
                  getSubAgentOptions(students),
                  filters.sub_agent
                )}
                onChange={handleMultiSelectChange("sub_agent")}
                placeholder="Select sub-agents"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Col>
        )}
        <Col md={1} className="d-flex align-items-center">
          <Button
            variant="outline-secondary"
            onClick={handleResetFilters}
            className="reset-filters-btn"
            title="Reset all filters"
          >
            <i className="fas fa-undo"></i>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchAndFilter;
