import React, { useState, useEffect } from "react";
import { Container, Form, Button, Card, Alert } from "react-bootstrap";
import AppNavbar from "./Navbar";
import "../styles/Profile.css";
import config from "../config";
import { useData } from '../context/DataContext';

const Profile = ({ user, onLogout }) => {
  const { cachedData, updateCache } = useData();
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone_number: user?.phone_number || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState({ type: "", text: "" });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${config.API_URL}/user`, {
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setFormData(prev => ({
            ...prev,
            name: userData.name || "",
            email: userData.email || "",
            phone_number: userData.phone_number || "",
          }));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: "", text: "" }); // Clear any existing messages
    
    // Validate passwords match if changing password
    if (formData.newPassword || formData.confirmPassword) {
      if (formData.newPassword !== formData.confirmPassword) {
        setMessage({
          type: "danger",
          text: "New passwords do not match",
        });
        return;
      }
      if (!formData.currentPassword) {
        setMessage({
          type: "danger",
          text: "Current password is required to change password",
        });
        return;
      }
    }

    try {
      const response = await fetch(`${config.API_URL}/user/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        // Update the cache with the new user data
        if (cachedData.users) {
          const updatedUsers = cachedData.users.map(u => 
            u.id === data.user.id ? { ...u, ...data.user } : u
          );
          updateCache('users', updatedUsers);
        }

        setMessage({
          type: "success",
          text: "Profile updated successfully!",
        });
        
        // Clear password fields after successful update
        setFormData(prev => ({
          ...prev,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }));

        // Refresh the page after a short delay to show the success message
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setMessage({
          type: "danger",
          text: data.error || "Failed to update profile",
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage({
        type: "danger",
        text: "An error occurred while updating the profile. Please try again later.",
      });
    }
  };

  return (
    <>
      <AppNavbar user={user} onLogout={onLogout} />
      <Container className="profile-container mt-5 pt-5">
        <Card className="profile-card">
          <Card.Header as="h4" className="text-center">Profile Settings</Card.Header>
          <Card.Body>
            {message.text && (
              <Alert variant={message.type} dismissible onClose={() => setMessage({ type: "", text: "" })}>
                {message.text}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                />
              </Form.Group>

              <hr className="my-4" />

              <Form.Group className="mb-3">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  type="password"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Form.Group>

              <div className="d-grid">
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Profile; 