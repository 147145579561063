import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Table,
  Button,
  Form,
  Modal,
  Card,
  Alert,
  Dropdown,
} from "react-bootstrap";
import AppNavbar from "./Navbar";
import Footer from "./Footer";
import "../styles/AdminPanel.css";
import config from "../config";
import { useData } from "../context/DataContext";
import AddStudentModal from "./AddStudentModal";

const SidebarMenuItem = React.memo(({ icon, label, isActive, onClick }) => (
  <button
    className={`sidebar-menu-item ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    <i className={`fas ${icon} me-2`}></i>
    {label}
  </button>
));

const AdminPanel = ({ user, onLogout }) => {
  const { cachedData, updateCache } = useData();
  const [users, setUsers] = useState(cachedData.users || []);
  const [students, setStudents] = useState(cachedData.adminStudents || []);
  const [loading, setLoading] = useState(
    !cachedData.users || !cachedData.adminStudents
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
  const [showEditStudentModal, setShowEditStudentModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editPermissions, setEditPermissions] = useState([]);
  const [activeSection, setActiveSection] = useState("users");
  const [currentUser] = useState(user);
  const [createUserForm, setCreateUserForm] = useState({
    name: "",
    email: "",
    password: "",
    phone_number: "",
    is_admin: false,
    permissions: [],
  });
  const [createError, setCreateError] = useState("");
  const [newOwnerId, setNewOwnerId] = useState("");
  const [universities, setUniversities] = useState(
    cachedData.universities || []
  );
  const [showUniversityModal, setShowUniversityModal] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [universityForm, setUniversityForm] = useState({
    name: "",
  });
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [studentSearchQuery, setStudentSearchQuery] = useState("");
  const [universitySearchQuery, setUniversitySearchQuery] = useState("");
  const [subAgentSearchQuery, setSubAgentSearchQuery] = useState("");
  const [userFilter, setUserFilter] = useState("all");
  const [activeFilter, setActiveFilter] = useState("all");
  const [subAgentFilter, setSubAgentFilter] = useState("all");
  const [updateIntervalForm, setUpdateIntervalForm] = useState({
    value: "24",
    description: "Number of hours between visa status updates",
  });
  const [settingsError, setSettingsError] = useState("");
  const [nextUpdateTime, setNextUpdateTime] = useState(null);
  const [editUserForm, setEditUserForm] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
  });
  const [editUserError, setEditUserError] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showSubAgentModal, setShowSubAgentModal] = useState(false);
  const [selectedSubAgent, setSelectedSubAgent] = useState(null);
  const [subAgentForm, setSubAgentForm] = useState({
    name: "",
    phone_number: "",
    counselor_id: "",
  });
  const [subAgents, setSubAgents] = useState(cachedData.subAgents || []);
  const [showImportResultsModal, setShowImportResultsModal] = useState(false);
  const [importResults, setImportResults] = useState(null);
  const [lastImportTime, setLastImportTime] = useState(null);
  const [isImporting, setIsImporting] = useState(false);

  const availablePermissions = [
    { id: "edit_students", name: "Edit Students" },
    { id: "add_student", name: "Add Students" },
    { id: "view_all_students", name: "View All Students" },
    { id: "see_all_subagents", name: "See All Sub-agents" },
    { id: "see_all_counselors", name: "See All Counselors" },
  ];

  const fetchUsers = useCallback(async () => {
    if (cachedData.users) {
      setUsers(cachedData.users);
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/users`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        updateCache("users", data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [cachedData.users, updateCache]);

  const fetchStudents = useCallback(async () => {
    if (cachedData.adminStudents) {
      setStudents(cachedData.adminStudents);
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/admin/students`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
        updateCache("adminStudents", data);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  }, [cachedData.adminStudents, updateCache]);

  const fetchUniversities = useCallback(async () => {
    if (cachedData.universities) {
      setUniversities(cachedData.universities);
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/admin/universities`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setUniversities(data);
        updateCache("universities", data);
      }
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  }, [cachedData.universities, updateCache]);

  const fetchSettings = useCallback(async () => {
    if (cachedData.settings) {
      const updateInterval = cachedData.settings.find(
        (s) => s.key === "visa_status_update_interval"
      );
      if (updateInterval) {
        setUpdateIntervalForm({
          value: updateInterval.value,
          description:
            updateInterval.description ||
            "Number of hours between visa status updates",
        });
      }
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/admin/settings`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        updateCache("settings", data);
        const updateInterval = data.find(
          (s) => s.key === "visa_status_update_interval"
        );
        if (updateInterval) {
          setUpdateIntervalForm({
            value: updateInterval.value,
            description:
              updateInterval.description ||
              "Number of hours between visa status updates",
          });
        }
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  }, [cachedData.settings, updateCache]);

  const fetchSubAgents = useCallback(async () => {
    if (cachedData.subAgents) {
      setSubAgents(cachedData.subAgents);
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/sub-agents`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setSubAgents(data);
        updateCache("subAgents", data);
      }
    } catch (error) {
      console.error("Error fetching sub-agents:", error);
    }
  }, [cachedData.subAgents, updateCache]);

  useEffect(() => {
    fetchUsers();
    fetchStudents();
    fetchUniversities();
    fetchSettings();
  }, [fetchUsers, fetchStudents, fetchUniversities, fetchSettings]);

  useEffect(() => {
    if (activeSection === "sub-agents") {
      fetchSubAgents();
    }
  }, [activeSection, fetchSubAgents]);

  useEffect(() => {
    // Fetch last upload time when component mounts
    const fetchLastUpload = async () => {
      if (cachedData.lastImport) {
        setLastImportTime(cachedData.lastImport);
        return;
      }

      try {
        const response = await fetch(`${config.API_URL}/students/last-import`, {
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setLastImportTime(data.last_import);
          updateCache("lastImport", data.last_import);
        }
      } catch (error) {
        console.error("Error fetching last upload time:", error);
      }
    };

    fetchLastUpload();
  }, [cachedData.lastImport, updateCache]);

  const handleReimport = async () => {
    setIsImporting(true);
    try {
      const response = await fetch(`${config.API_URL}/students/reimport`, {
        method: "POST",
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        setImportResults(data.import_results);
        setShowImportResultsModal(true);
        setLastImportTime(data.last_import);
        updateCache("lastImport", data.last_import);

        // Update the students cache with the new data
        setStudents(data.all_students);
        updateCache("adminStudents", data.all_students);
      } else {
        alert(data.error || "Failed to re-import students");
      }
    } catch (error) {
      console.error("Error re-importing data:", error);
      alert("An error occurred while re-importing the data");
    } finally {
      setIsImporting(false);
    }
  };

  const handleEditPermissions = (user) => {
    setSelectedUser(user);
    setEditPermissions(user.permissions || []);
    setShowEditModal(true);
  };

  const handlePermissionChange = (permission) => {
    setEditPermissions((prev) => {
      if (prev.includes(permission)) {
        return prev.filter((p) => p !== permission);
      }
      return [...prev, permission];
    });
  };

  const handleSavePermissions = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/users/${selectedUser.id}/permissions`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            permissions: editPermissions,
            is_admin: selectedUser.is_admin,
          }),
        }
      );

      if (response.ok) {
        const updatedUsers = users.map((user) =>
          user.id === selectedUser.id
            ? { ...user, permissions: editPermissions }
            : user
        );
        setUsers(updatedUsers);
        updateCache("users", updatedUsers);
        setShowEditModal(false);
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  const handleAdminStatusChange = async (userId, newStatus) => {
    try {
      const response = await fetch(
        `${config.API_URL}/users/${userId}/admin-status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ is_admin: newStatus }),
        }
      );

      if (response.ok) {
        const updatedUsers = users.map((user) =>
          user.id === userId ? { ...user, is_admin: newStatus } : user
        );
        setUsers(updatedUsers);
        updateCache("users", updatedUsers);
      }
    } catch (error) {
      console.error("Error updating admin status:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    // Find the user's students
    const userStudents = students.filter(
      (student) => student.user_id === userId
    );

    if (userStudents.length > 0) {
      // Set the user to be deleted and show transfer modal
      setUserToDelete(users.find((u) => u.id === userId));
      setShowTransferModal(true);
      return;
    }

    if (
      !window.confirm(
        "Are you sure you want to delete this user? This action cannot be undone."
      )
    ) {
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/users/${userId}`, {
        method: "DELETE",
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        const updatedUsers = users.filter((user) => user.id !== userId);
        setUsers(updatedUsers);
        updateCache("users", updatedUsers);
      } else {
        alert(data.error || "Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user");
    }
  };

  const handleChangeStudentOwner = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/admin/students/${selectedStudent.id}/change-owner`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ user_id: parseInt(newOwnerId) }),
        }
      );

      if (response.ok) {
        const newOwner = users.find((u) => u.id === parseInt(newOwnerId));
        const updatedStudents = students.map((student) =>
          student.id === selectedStudent.id
            ? {
                ...student,
                user_id: parseInt(newOwnerId),
                user_name: newOwner.name,
              }
            : student
        );
        setStudents(updatedStudents);
        updateCache("adminStudents", updatedStudents);

        // Also update the regular students cache if it exists
        if (cachedData.students) {
          const regularStudents = cachedData.students.map((student) =>
            student.id === selectedStudent.id
              ? {
                  ...student,
                  user_id: parseInt(newOwnerId),
                  user_name: newOwner.name,
                }
              : student
          );
          updateCache("students", regularStudents);
        }

        setShowChangeOwnerModal(false);
        setSelectedStudent(null);
        setNewOwnerId("");
      } else {
        const errorData = await response.json();
        alert(errorData.error || "Failed to change student owner");
      }
    } catch (error) {
      console.error("Error changing student owner:", error);
      alert("An error occurred while changing the student owner");
    }
  };

  const handleChangeOwner = async () => {
    if (!userToDelete || !newOwnerId) return;

    try {
      const userStudents = students.filter(
        (student) => student.user_id === userToDelete.id
      );
      let hasError = false;
      const newOwner = users.find((u) => u.id === parseInt(newOwnerId));

      for (const student of userStudents) {
        const response = await fetch(
          `${config.API_URL}/admin/students/${student.id}/change-owner`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ user_id: parseInt(newOwnerId) }),
          }
        );

        if (!response.ok) {
          hasError = true;
          const errorData = await response.json();
          alert(
            `Failed to transfer student ${student.name}: ${
              errorData.error || "Unknown error"
            }`
          );
          break;
        }
      }

      if (!hasError) {
        try {
          const deleteResponse = await fetch(
            `${config.API_URL}/users/${userToDelete.id}`,
            {
              method: "DELETE",
              credentials: "include",
            }
          );

          const deleteData = await deleteResponse.json();

          if (deleteResponse.ok) {
            // Update users cache
            const updatedUsers = users.filter(
              (user) => user.id !== userToDelete.id
            );
            setUsers(updatedUsers);
            updateCache("users", updatedUsers);

            // Update students cache
            const updatedStudents = students.map((student) =>
              student.user_id === userToDelete.id
                ? {
                    ...student,
                    user_id: parseInt(newOwnerId),
                    user_name: newOwner.name,
                  }
                : student
            );
            setStudents(updatedStudents);
            updateCache("adminStudents", updatedStudents);

            // Update regular students cache if it exists
            if (cachedData.students) {
              const regularStudents = cachedData.students.map((student) =>
                student.user_id === userToDelete.id
                  ? {
                      ...student,
                      user_id: parseInt(newOwnerId),
                      user_name: newOwner.name,
                    }
                  : student
              );
              updateCache("students", regularStudents);
            }

            setShowTransferModal(false);
            setNewOwnerId("");
            setUserToDelete(null);
          } else {
            alert(deleteData.error || "Failed to delete user");
          }
        } catch (error) {
          console.error("Error deleting user:", error);
          alert("An error occurred while deleting the user");
        }
      }
    } catch (error) {
      console.error("Error transferring students:", error);
      alert("An error occurred while transferring students");
    }
  };

  const handleCreateUser = async () => {
    // Validate required fields
    if (!createUserForm.name.trim()) {
      setCreateError("Name is required");
      return;
    }
    if (!createUserForm.email.trim()) {
      setCreateError("Email is required");
      return;
    }
    if (!createUserForm.password.trim()) {
      setCreateError("Password is required");
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(createUserForm),
      });

      const data = await response.json();

      if (response.ok) {
        const updatedUsers = [...users, data];
        setUsers(updatedUsers);
        updateCache("users", updatedUsers);
        setShowCreateModal(false);
        setCreateUserForm({
          name: "",
          email: "",
          password: "",
          phone_number: "",
          is_admin: false,
          permissions: [],
        });
        setCreateError("");
      } else {
        setCreateError(data.error || "Failed to create user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setCreateError("An error occurred while creating the user");
    }
  };

  const handleCreateFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCreateUserForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreatePermissionChange = (permission) => {
    setCreateUserForm((prev) => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter((p) => p !== permission)
        : [...prev.permissions, permission],
    }));
  };

  const handleEditStudent = (student) => {
    setSelectedStudent(student);
    setShowEditStudentModal(true);
  };

  const handleStudentUpdated = (updatedStudent, isEditing) => {
    if (isEditing) {
      const updatedStudents = students.map((student) =>
        student.id === updatedStudent.id ? updatedStudent : student
      );
      setStudents(updatedStudents);
      updateCache("adminStudents", updatedStudents);
    }
  };

  const handleDeleteStudent = async (student) => {
    if (
      window.confirm(
        "Are you sure you want to delete this student? This action cannot be undone."
      )
    ) {
      try {
        const response = await fetch(
          `${config.API_URL}/admin/students/${student.id}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.ok) {
          // Update admin students cache
          const updatedStudents = students.filter((s) => s.id !== student.id);
          setStudents(updatedStudents);
          updateCache("adminStudents", updatedStudents);

          // Update regular students cache if it exists
          if (cachedData.students) {
            const regularStudents = cachedData.students.filter(
              (s) => s.id !== student.id
            );
            updateCache("students", regularStudents);
          }
        }
      } catch (error) {
        console.error("Error deleting student:", error);
      }
    }
  };

  const handleToggleStudentStatus = async (student) => {
    try {
      const response = await fetch(
        `${config.API_URL}/admin/students/${student.id}/toggle-status`,
        {
          method: "PUT",
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const updatedStudents = students.map((s) =>
          s.id === student.id ? { ...s, is_active: data.is_active } : s
        );
        setStudents(updatedStudents);
        updateCache("adminStudents", updatedStudents);
      }
    } catch (error) {
      console.error("Error toggling student status:", error);
    }
  };

  const handleCreateUniversity = async () => {
    try {
      const response = await fetch(`${config.API_URL}/admin/universities`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(universityForm),
      });

      if (response.ok) {
        const newUniversity = await response.json();
        const updatedUniversities = [...universities, newUniversity];
        setUniversities(updatedUniversities);
        updateCache("universities", updatedUniversities);
        setShowUniversityModal(false);
        setUniversityForm({ name: "" });
      }
    } catch (error) {
      console.error("Error creating university:", error);
    }
  };

  const handleUpdateUniversity = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/admin/universities/${selectedUniversity.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(universityForm),
        }
      );

      if (response.ok) {
        const updatedUniversity = await response.json();
        const updatedUniversities = universities.map((uni) =>
          uni.id === selectedUniversity.id ? updatedUniversity : uni
        );
        setUniversities(updatedUniversities);
        updateCache("universities", updatedUniversities);
        setShowUniversityModal(false);
        setSelectedUniversity(null);
      }
    } catch (error) {
      console.error("Error updating university:", error);
    }
  };

  const handleDeleteUniversity = async (id) => {
    if (window.confirm("Are you sure you want to delete this university?")) {
      try {
        const response = await fetch(
          `${config.API_URL}/admin/universities/${id}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.ok) {
          const updatedUniversities = universities.filter(
            (uni) => uni.id !== id
          );
          setUniversities(updatedUniversities);
          updateCache("universities", updatedUniversities);
        }
      } catch (error) {
        console.error("Error deleting university:", error);
      }
    }
  };

  const handleUpdateIntervalSubmit = async (e) => {
    e.preventDefault();
    setSettingsError("");

    try {
      const response = await fetch(
        `${config.API_URL}/admin/settings/visa_status_update_interval`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(updateIntervalForm),
        }
      );

      if (response.ok) {
        const data = await response.json();
        await fetchSettings();
        setNextUpdateTime(data.next_update);
      } else {
        const data = await response.json();
        setSettingsError(data.error || "Failed to update settings");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      setSettingsError("An error occurred while updating settings");
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditUserForm({
      name: user.name,
      email: user.email,
      phone_number: user.phone_number || "",
      password: "",
    });
    setShowEditUserModal(true);
  };

  const handleEditUserSubmit = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/users/${selectedUser.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(editUserForm),
        }
      );

      const data = await response.json();

      if (response.ok) {
        const updatedUsers = users.map((user) =>
          user.id === selectedUser.id ? { ...user, ...data.user } : user
        );
        setUsers(updatedUsers);
        updateCache("users", updatedUsers);
        setShowEditUserModal(false);
        setEditUserError("");
      } else {
        setEditUserError(data.error || "Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setEditUserError("An error occurred while updating the user");
    }
  };

  const handleEditUserFormChange = (e) => {
    const { name, value } = e.target;
    setEditUserForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateSubAgent = async () => {
    try {
      // Validate required fields
      if (!subAgentForm.name || !subAgentForm.counselor_id) {
        alert("Name and Counselor are required fields");
        return;
      }

      const response = await fetch(`${config.API_URL}/sub-agents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(subAgentForm),
      });

      if (response.ok) {
        const newSubAgent = await response.json();
        // Find the counselor name from users array
        const counselor = users.find(
          (user) => user.id.toString() === subAgentForm.counselor_id
        );
        const subAgentWithCounselor = {
          ...newSubAgent,
          counselor_name: counselor ? counselor.name : "Unknown",
        };
        const updatedSubAgents = [...subAgents, subAgentWithCounselor];
        setSubAgents(updatedSubAgents);
        updateCache("subAgents", updatedSubAgents);
        setShowSubAgentModal(false);
        setSubAgentForm({ name: "", phone_number: "", counselor_id: "" });
      }
    } catch (error) {
      console.error("Error creating sub-agent:", error);
    }
  };

  const handleUpdateSubAgent = async () => {
    try {
      // Validate required fields
      if (!subAgentForm.name || !subAgentForm.counselor_id) {
        alert("Name and Counselor are required fields");
        return;
      }

      const response = await fetch(
        `${config.API_URL}/sub-agents/${selectedSubAgent.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(subAgentForm),
        }
      );

      if (response.ok) {
        const updatedSubAgent = await response.json();
        // Find the counselor name from users array
        const counselor = users.find(
          (user) => user.id.toString() === subAgentForm.counselor_id
        );
        const subAgentWithCounselor = {
          ...updatedSubAgent,
          counselor_name: counselor ? counselor.name : "Unknown",
        };
        const updatedSubAgents = subAgents.map((agent) =>
          agent.id === updatedSubAgent.id ? subAgentWithCounselor : agent
        );
        setSubAgents(updatedSubAgents);
        updateCache("subAgents", updatedSubAgents);
        setShowSubAgentModal(false);
        setSelectedSubAgent(null);
        setSubAgentForm({ name: "", phone_number: "", counselor_id: "" });
      }
    } catch (error) {
      console.error("Error updating sub-agent:", error);
    }
  };

  const handleDeleteSubAgent = async (agentId) => {
    if (!window.confirm("Are you sure you want to delete this sub-agent?"))
      return;

    try {
      const response = await fetch(`${config.API_URL}/sub-agents/${agentId}`, {
        method: "DELETE",
        credentials: "include",
      });

      if (response.ok) {
        const updatedSubAgents = subAgents.filter(
          (agent) => agent.id !== agentId
        );
        setSubAgents(updatedSubAgents);
        updateCache("subAgents", updatedSubAgents);
      }
    } catch (error) {
      console.error("Error deleting sub-agent:", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    setIsImporting(true);

    try {
      const response = await fetch(`${config.API_URL}/students/upload`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setImportResults(data.import_results);
        setShowImportResultsModal(true);
        setLastImportTime(data.last_import);
        updateCache("lastImport", data.last_import);

        // Update the students cache with the new data
        setStudents(data.all_students);
        updateCache("adminStudents", data.all_students);
      } else {
        alert(data.error || "Failed to import students");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("An error occurred while uploading the file");
    } finally {
      setIsImporting(false);
      // Reset the file input
      event.target.value = "";
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await fetch(`${config.API_URL}/students/template`, {
        credentials: "include",
      });

      if (response.ok) {
        // Create a URL for the blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link and click it
        const a = document.createElement("a");
        a.href = url;
        a.download = "student_template.xlsx";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        alert("Failed to download template");
      }
    } catch (error) {
      console.error("Error downloading template:", error);
      alert("An error occurred while downloading the template");
    }
  };

  const renderContent = () => {
    const filteredUsers = users.filter((user) => {
      const matchesSearch =
        !userSearchQuery ||
        user.name.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(userSearchQuery.toLowerCase());

      const matchesFilter =
        userFilter === "all" ||
        (userFilter === "admin" && user.is_admin) ||
        (userFilter === "non-admin" && !user.is_admin);

      return matchesSearch && matchesFilter;
    });

    const filteredStudents = students.filter((student) => {
      const matchesSearch =
        !studentSearchQuery ||
        student.name.toLowerCase().includes(studentSearchQuery.toLowerCase()) ||
        student.travel_doc_no
          .toLowerCase()
          .includes(studentSearchQuery.toLowerCase());

      const matchesFilter =
        activeFilter === "all" ||
        (activeFilter === "active" && student.is_active) ||
        (activeFilter === "inactive" && !student.is_active);

      return matchesSearch && matchesFilter;
    });

    const filteredUniversities = universities.filter((uni) => {
      const matchesSearch =
        !universitySearchQuery ||
        uni.name.toLowerCase().includes(universitySearchQuery.toLowerCase());
      return matchesSearch;
    });

    const filteredSubAgents = subAgents.filter((agent) => {
      const matchesSearch =
        !subAgentSearchQuery ||
        agent.name.toLowerCase().includes(subAgentSearchQuery.toLowerCase()) ||
        (agent.phone_number &&
          agent.phone_number
            .toLowerCase()
            .includes(subAgentSearchQuery.toLowerCase()));

      const matchesFilter =
        subAgentFilter === "all" ||
        (subAgentFilter === "with-counselor" && agent.counselor_id) ||
        (subAgentFilter === "without-counselor" && !agent.counselor_id);

      return matchesSearch && matchesFilter;
    });

    switch (activeSection) {
      case "users":
        return (
          <div className="admin-table-container">
            <div className="admin-table-header">
              <div className="d-flex align-items-center gap-2">
                <h3>User Management</h3>
                <span className="badge bg-primary">
                  {userSearchQuery
                    ? `${filteredUsers.length} of ${users.length} Users`
                    : `${users.length} Users`}
                </span>
              </div>
              <div className="d-flex gap-2 mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search users..."
                  value={userSearchQuery}
                  onChange={(e) => setUserSearchQuery(e.target.value)}
                  className="w-auto"
                />
                <Form.Select
                  value={userFilter}
                  onChange={(e) => setUserFilter(e.target.value)}
                  className="w-auto"
                >
                  <option value="all">All Users</option>
                  <option value="admin">Admins</option>
                  <option value="non-admin">Non-Admins</option>
                </Form.Select>
              </div>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => setShowCreateModal(true)}
              >
                <i className="fas fa-plus me-2"></i>
                Create New User
              </Button>
            </div>

            <Table hover responsive className="admin-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Permissions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`admin-switch-${user.id}`}
                        label={user.is_admin ? "Admin" : "User"}
                        checked={user.is_admin}
                        onChange={(e) =>
                          handleAdminStatusChange(user.id, e.target.checked)
                        }
                        disabled={user.id === currentUser?.id}
                      />
                    </td>
                    <td>
                      {user.permissions?.map((permission) => (
                        <span key={permission} className="badge bg-info me-1">
                          {permission}
                        </span>
                      ))}
                    </td>
                    <td className="admin-table-actions">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEditUser(user)}
                        className="me-2"
                      >
                        <i className="fas fa-edit me-1"></i>
                        Edit
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEditPermissions(user)}
                        disabled={user.is_admin}
                        className="me-2"
                      >
                        Edit Permissions
                      </Button>
                      {user.id !== currentUser?.id && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          <i className="fas fa-trash me-1"></i>
                          Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        );
      case "students":
        return (
          <div className="admin-table-container">
            <div className="admin-table-header">
              <div className="admin-table-title">
                <h3>Student Management</h3>
                <span className="badge bg-primary">
                  {studentSearchQuery || activeFilter !== "all"
                    ? `${filteredStudents.length} of ${students.length} Students`
                    : `${students.length} Students`}
                </span>
              </div>

              <div className="admin-table-controls">
                <div className="search-section">
                  <Form.Control
                    type="text"
                    placeholder="Search by name or passport number"
                    value={studentSearchQuery}
                    onChange={(e) => setStudentSearchQuery(e.target.value)}
                  />
                  <Form.Select
                    value={activeFilter}
                    onChange={(e) => setActiveFilter(e.target.value)}
                  >
                    <option value="all">All Students</option>
                    <option value="active">Active Only</option>
                    <option value="inactive">Inactive Only</option>
                  </Form.Select>
                </div>

                <div className="action-section">
                  <Dropdown className="import-dropdown">
                    <Dropdown.Toggle variant="primary" disabled={isImporting}>
                      {isImporting ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Importing Students...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-file-import me-2"></i>
                          Import Students
                        </>
                      )}
                    </Dropdown.Toggle>
                    {!isImporting && (
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleDownloadTemplate}>
                          <i className="fas fa-download"></i>
                          Download Template
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <div className="upload-section">
                          <small className="text-muted d-block mb-2">
                            Upload new file:
                          </small>
                          <Form.Control
                            type="file"
                            accept=".xlsx,.xls"
                            onChange={handleFileUpload}
                            size="sm"
                          />
                        </div>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={handleReimport}
                          disabled={!lastImportTime}
                        >
                          <i className="fas fa-redo"></i>
                          Re-import Last File
                        </Dropdown.Item>
                        {lastImportTime && (
                          <div className="last-import-info">
                            Last upload:{" "}
                            {new Date(lastImportTime).toLocaleString()}
                          </div>
                        )}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </div>
              </div>
            </div>

            <Table hover responsive className="admin-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Travel Document No.</th>
                  <th>Current Owner</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student) => (
                  <tr
                    key={student.id}
                    className={!student.is_active ? "table-secondary" : ""}
                  >
                    <td>{student.name}</td>
                    <td>{student.travel_doc_no}</td>
                    <td>{student.user_name}</td>
                    <td>
                      <span
                        className={`badge ${
                          student.is_active ? "bg-success" : "bg-danger"
                        }`}
                      >
                        {student.is_active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td className="admin-table-actions">
                      <div className="d-flex gap-2">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleEditStudent(student)}
                        >
                          <i className="fas fa-edit me-1"></i>
                          Edit
                        </Button>
                        <Button
                          variant={student.is_active ? "warning" : "success"}
                          size="sm"
                          onClick={() => handleToggleStudentStatus(student)}
                        >
                          <i
                            className={`fas fa-${
                              student.is_active ? "ban" : "check"
                            } me-1`}
                          ></i>
                          {student.is_active ? "Deactivate" : "Activate"}
                        </Button>
                        <Button
                          variant="info"
                          size="sm"
                          onClick={() => {
                            setSelectedStudent(student);
                            setShowChangeOwnerModal(true);
                          }}
                        >
                          <i className="fas fa-exchange-alt me-1"></i>
                          Change Owner
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteStudent(student)}
                        >
                          <i className="fas fa-trash me-1"></i>
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        );
      case "universities":
        return (
          <div className="admin-table-container">
            <div className="admin-table-header">
              <div className="d-flex align-items-center gap-2">
                <h3>University Management</h3>
                <span className="badge bg-primary">
                  {universitySearchQuery
                    ? `${filteredUniversities.length} of ${universities.length} Universities`
                    : `${universities.length} Universities`}
                </span>
              </div>
              <div className="d-flex gap-2 mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search universities..."
                  value={universitySearchQuery}
                  onChange={(e) => setUniversitySearchQuery(e.target.value)}
                  className="w-auto"
                />
              </div>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => {
                  setSelectedUniversity(null);
                  setUniversityForm({ name: "" });
                  setShowUniversityModal(true);
                }}
              >
                <i className="fas fa-plus me-2"></i>
                Add University
              </Button>
            </div>

            <Table hover responsive className="admin-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUniversities.map((university) => (
                  <tr key={university.id}>
                    <td>{university.name}</td>
                    <td className="admin-table-actions">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                          setSelectedUniversity(university);
                          setUniversityForm(university);
                          setShowUniversityModal(true);
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteUniversity(university.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        );
      case "settings":
        return (
          <div className="admin-table-container">
            <div className="admin-table-header">
              <h3>Settings</h3>
            </div>
            <div className="settings-content">
              <Card className="mb-4">
                <Card.Header>
                  <h5 className="mb-0">Visa Status Update Settings</h5>
                </Card.Header>
                <Card.Body>
                  {settingsError && (
                    <Alert
                      variant="danger"
                      dismissible
                      onClose={() => setSettingsError("")}
                    >
                      {settingsError}
                    </Alert>
                  )}
                  <Form onSubmit={handleUpdateIntervalSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Update Interval (hours)</Form.Label>
                      <Form.Control
                        type="number"
                        min="0.1"
                        max="168"
                        step="0.1"
                        value={updateIntervalForm.value}
                        onChange={(e) =>
                          setUpdateIntervalForm((prev) => ({
                            ...prev,
                            value: e.target.value,
                          }))
                        }
                        required
                      />
                      <Form.Text className="text-muted">
                        How often the system should check for visa status
                        updates (in hours). Minimum 0.1 hours (6 minutes),
                        maximum 168 hours (1 week).
                      </Form.Text>
                    </Form.Group>
                    {nextUpdateTime && (
                      <Alert variant="success" className="mt-3">
                        Time changed successfully
                      </Alert>
                    )}
                    <Button type="submit" variant="primary">
                      Save Changes
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        );
      case "sub-agents":
        return (
          <div className="admin-table-container">
            <div className="admin-table-header">
              <div className="d-flex align-items-center gap-2">
                <h3>Sub-Agent Management</h3>
                <span className="badge bg-primary">
                  {subAgentSearchQuery
                    ? `${filteredSubAgents.length} of ${subAgents.length} Sub-Agents`
                    : `${subAgents.length} Sub-Agents`}
                </span>
              </div>
              <div className="d-flex gap-2 mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search sub-agents..."
                  value={subAgentSearchQuery}
                  onChange={(e) => setSubAgentSearchQuery(e.target.value)}
                  className="w-auto"
                />
                <Form.Select
                  value={subAgentFilter}
                  onChange={(e) => setSubAgentFilter(e.target.value)}
                  className="w-auto"
                >
                  <option value="all">All Sub-Agents</option>
                  <option value="with-counselor">With Counselor</option>
                  <option value="without-counselor">Without Counselor</option>
                </Form.Select>
              </div>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => {
                  setSelectedSubAgent(null);
                  setSubAgentForm({
                    name: "",
                    phone_number: "",
                    counselor_id: "",
                  });
                  setShowSubAgentModal(true);
                }}
              >
                <i className="fas fa-plus me-2"></i>
                Add Sub-Agent
              </Button>
            </div>

            <Table hover responsive className="admin-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Counselor</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredSubAgents.map((agent) => (
                  <tr key={agent.id}>
                    <td>{agent.name}</td>
                    <td>{agent.phone_number || "N/A"}</td>
                    <td>{agent.counselor_name}</td>
                    <td className="admin-table-actions">
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                          setSelectedSubAgent(agent);
                          setSubAgentForm({
                            name: agent.name,
                            phone_number: agent.phone_number || "",
                            counselor_id: agent.counselor_id || "",
                          });
                          setShowSubAgentModal(true);
                        }}
                      >
                        <i className="fas fa-edit me-1"></i>
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteSubAgent(agent.id)}
                      >
                        <i className="fas fa-trash me-1"></i>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Modal
              show={showSubAgentModal}
              onHide={() => {
                setShowSubAgentModal(false);
                setSelectedSubAgent(null);
                setSubAgentForm({
                  name: "",
                  phone_number: "",
                  counselor_id: "",
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {selectedSubAgent ? "Edit Sub-Agent" : "Add Sub-Agent"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={subAgentForm.name}
                      onChange={(e) =>
                        setSubAgentForm((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      value={subAgentForm.phone_number}
                      onChange={(e) =>
                        setSubAgentForm((prev) => ({
                          ...prev,
                          phone_number: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Counselor</Form.Label>
                    <Form.Select
                      value={subAgentForm.counselor_id}
                      onChange={(e) =>
                        setSubAgentForm((prev) => ({
                          ...prev,
                          counselor_id: e.target.value,
                        }))
                      }
                      required
                    >
                      <option value="">Select Counselor</option>
                      {users
                        .filter(
                          (user) =>
                            !user.is_admin || user.id === currentUser?.id
                        )
                        .map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}{" "}
                            {user.id === currentUser?.id ? "(You)" : ""}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowSubAgentModal(false);
                    setSelectedSubAgent(null);
                    setSubAgentForm({
                      name: "",
                      phone_number: "",
                      counselor_id: "",
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={
                    selectedSubAgent
                      ? handleUpdateSubAgent
                      : handleCreateSubAgent
                  }
                >
                  {selectedSubAgent ? "Save Changes" : "Add Sub-Agent"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      default:
        return null;
    }
  };

  if (!user?.is_admin) {
    return (
      <>
        <AppNavbar user={user} onLogout={onLogout} />
        <Container className="mt-4">
          <h2>Access Denied</h2>
          <p>You do not have permission to access this page.</p>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <AppNavbar user={user} onLogout={onLogout} />
      <div className="admin-container">
        <div className="admin-layout">
          <div className="admin-sidebar">
            <div className="sidebar-header">
              <h4>Admin Panel</h4>
            </div>
            <ul className="sidebar-menu">
              <li>
                <SidebarMenuItem
                  icon="fa-users"
                  label="User Management"
                  isActive={activeSection === "users"}
                  onClick={() => setActiveSection("users")}
                />
              </li>
              <li>
                <SidebarMenuItem
                  icon="fa-user-friends"
                  label="Sub-agents"
                  isActive={activeSection === "sub-agents"}
                  onClick={() => setActiveSection("sub-agents")}
                />
              </li>
              <li>
                <SidebarMenuItem
                  icon="fa-user-graduate"
                  label="Student Management"
                  isActive={activeSection === "students"}
                  onClick={() => setActiveSection("students")}
                />
              </li>
              <li>
                <SidebarMenuItem
                  icon="fa-university"
                  label="Universities"
                  isActive={activeSection === "universities"}
                  onClick={() => setActiveSection("universities")}
                />
              </li>
              <li>
                <SidebarMenuItem
                  icon="fa-cog"
                  label="Settings"
                  isActive={activeSection === "settings"}
                  onClick={() => setActiveSection("settings")}
                />
              </li>
            </ul>
          </div>

          <div className="admin-content">
            {loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              renderContent()
            )}
          </div>
        </div>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User Permissions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>User: {selectedUser?.name}</p>
            <Form>
              {availablePermissions.map((permission) => (
                <Form.Check
                  key={permission.id}
                  type="checkbox"
                  id={`permission-${permission.id}`}
                  label={permission.name}
                  checked={editPermissions.includes(permission.id)}
                  onChange={() => handlePermissionChange(permission.id)}
                />
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSavePermissions}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Create New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {createError && (
              <Alert
                variant="danger"
                dismissible
                onClose={() => setCreateError("")}
              >
                {createError}
              </Alert>
            )}
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={createUserForm.name}
                  onChange={handleCreateFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={createUserForm.email}
                  onChange={handleCreateFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Password <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={createUserForm.password}
                  onChange={handleCreateFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone_number"
                  value={createUserForm.phone_number}
                  onChange={handleCreateFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="is_admin"
                  name="is_admin"
                  label="Admin User"
                  checked={createUserForm.is_admin}
                  onChange={handleCreateFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Permissions</Form.Label>
                {availablePermissions.map((permission) => (
                  <Form.Check
                    key={permission.id}
                    type="checkbox"
                    id={`create-permission-${permission.id}`}
                    label={permission.name}
                    checked={createUserForm.permissions.includes(permission.id)}
                    onChange={() => handleCreatePermissionChange(permission.id)}
                  />
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowCreateModal(false);
                setCreateError("");
                setCreateUserForm({
                  name: "",
                  email: "",
                  password: "",
                  phone_number: "",
                  is_admin: false,
                  permissions: [],
                });
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateUser}>
              Create User
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showChangeOwnerModal}
          onHide={() => {
            setShowChangeOwnerModal(false);
            setSelectedStudent(null);
            setNewOwnerId("");
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Student Owner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Student: {selectedStudent?.name}</p>
            <p>Current Owner: {selectedStudent?.user_name}</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>New Owner</Form.Label>
                <Form.Select
                  value={newOwnerId}
                  onChange={(e) => setNewOwnerId(e.target.value)}
                >
                  <option value="">Select new owner</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowChangeOwnerModal(false);
                setSelectedStudent(null);
                setNewOwnerId("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleChangeStudentOwner}
              disabled={!newOwnerId}
            >
              Change Owner
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showTransferModal}
          onHide={() => {
            setShowTransferModal(false);
            setNewOwnerId("");
            setUserToDelete(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transfer Students Before Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {userToDelete && (
              <>
                <div className="alert alert-warning">
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  User {userToDelete.name} has{" "}
                  {students.filter((s) => s.user_id === userToDelete.id).length}{" "}
                  student(s) assigned. Please select a user to transfer all
                  their students to before deletion.
                </div>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Transfer all students to:</Form.Label>
                    <Form.Select
                      value={newOwnerId}
                      onChange={(e) => setNewOwnerId(e.target.value)}
                    >
                      <option value="">Select new owner</option>
                      {users
                        .filter((u) => u.id !== userToDelete.id)
                        .map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name} ({user.email})
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowTransferModal(false);
                setNewOwnerId("");
                setUserToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleChangeOwner}
              disabled={!newOwnerId}
            >
              Transfer Students & Delete User
            </Button>
          </Modal.Footer>
        </Modal>

        <AddStudentModal
          show={showEditStudentModal}
          onHide={() => {
            setShowEditStudentModal(false);
            setSelectedStudent(null);
          }}
          onStudentAdded={handleStudentUpdated}
          studentToEdit={selectedStudent}
          isEditing={true}
          user={user}
          subAgents={subAgents}
        />

        <Modal
          show={showEditUserModal}
          onHide={() => setShowEditUserModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editUserError && (
              <Alert
                variant="danger"
                dismissible
                onClose={() => setEditUserError("")}
              >
                {editUserError}
              </Alert>
            )}
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editUserForm.name}
                  onChange={handleEditUserFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={editUserForm.email}
                  onChange={handleEditUserFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone_number"
                  value={editUserForm.phone_number}
                  onChange={handleEditUserFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  New Password (leave blank to keep current)
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={editUserForm.password}
                  onChange={handleEditUserFormChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEditUserModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleEditUserSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showUniversityModal}
          onHide={() => {
            setShowUniversityModal(false);
            setSelectedUniversity(null);
            setUniversityForm({
              name: "",
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedUniversity ? "Edit University" : "Add University"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={universityForm.name}
                  onChange={(e) =>
                    setUniversityForm((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowUniversityModal(false);
                setSelectedUniversity(null);
                setUniversityForm({
                  name: "",
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={
                selectedUniversity
                  ? handleUpdateUniversity
                  : handleCreateUniversity
              }
            >
              {selectedUniversity ? "Save Changes" : "Add University"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showImportResultsModal}
          onHide={() => setShowImportResultsModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Import Results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {importResults && (
              <div>
                <div className="mb-4">
                  <h5>Summary</h5>
                  <div className="d-flex gap-3">
                    <div className="text-success">
                      <strong>Successful:</strong> {importResults.successful}
                    </div>
                    <div className="text-danger">
                      <strong>Failed:</strong> {importResults.failed}
                    </div>
                  </div>
                </div>

                {importResults.successful_students?.length > 0 && (
                  <div className="mb-4">
                    <h5 className="text-success">
                      Successfully Imported Students
                    </h5>
                    <div className="list-group">
                      {importResults.successful_students.map(
                        (student, index) => (
                          <div
                            key={index}
                            className="list-group-item list-group-item-success"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <strong>{student.name}</strong>
                                {student.email && (
                                  <span className="ms-2">
                                    ({student.email})
                                  </span>
                                )}
                              </div>
                              <div>
                                <span className="badge bg-info">
                                  Status: {student.status}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                {importResults.failed_students?.length > 0 && (
                  <div>
                    <h5 className="text-danger">Failed Imports</h5>
                    <div className="list-group">
                      {importResults.failed_students.map((failure, index) => (
                        <div
                          key={index}
                          className="list-group-item list-group-item-danger"
                        >
                          <strong>{failure.name || "Unknown Student"}</strong>
                          <div className="small text-danger mt-1">
                            Row {failure.row} - Error: {failure.error}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowImportResultsModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default AdminPanel;
