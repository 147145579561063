/**
 * Main application component that handles routing and authentication state
 * Uses React Router for navigation and maintains user authentication status
 */

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AdminPanel from "./components/AdminPanel";
import Profile from "./components/Profile";
import Analytics from "./components/Analytics";
import "./styles/App.css";
import config from "./config";
import { DataProvider } from "./context/DataContext";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

// Configure axios defaults
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

function App() {
  // Authentication and user state management
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check authentication status when component mounts
  useEffect(() => {
    checkAuthStatus();
  }, []);

  /**
   * Verifies if user is already authenticated by checking with the backend
   * Makes a request to /api/check-auth endpoint with credentials
   */
  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/check-auth`);
      setUser(response.data);
      setIsLoggedIn(true);
    } catch (error) {
      // Error is silently handled as user is not authenticated
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles successful login by updating user state
   * @param {Object} userData - User information received after successful login
   */
  const handleLogin = async (userData) => {
    setUser(userData);
    setIsLoggedIn(true);
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  /**
   * Handles user logout by making request to logout endpoint
   * Clears user data, authentication state, and cached data on success
   */
  const handleLogout = async () => {
    try {
      const response = await axios.post(`${config.API_URL}/logout`);
      if (response.status === 200) {
        setUser(null);
        setIsLoggedIn(false);
      }
    } catch (error) {
      // Error is silently handled as user remains logged in
    }
  };

  // Show loading spinner while checking authentication status
  if (isLoading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <DataProvider>
      <Router>
        <div className="app-container">
          {/* Conditional rendering based on authentication status */}
          {isLoggedIn ? (
            <Routes>
              {/* Main dashboard route */}
              <Route
                path="/"
                element={<Dashboard user={user} onLogout={handleLogout} />}
              />
              {/* Protected admin route - only accessible to admin users */}
              <Route
                path="/admin"
                element={
                  user?.is_admin ? (
                    <AdminPanel user={user} onLogout={handleLogout} />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              {/* Analytics route - only accessible to admin users */}
              <Route
                path="/analytics"
                element={
                  user?.is_admin ? (
                    <Analytics user={user} onLogout={handleLogout} />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              {/* User profile route */}
              <Route
                path="/profile"
                element={<Profile user={user} onLogout={handleLogout} />}
              />
              {/* Redirect all unknown routes to dashboard */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            // Show login component if user is not authenticated
            <Login onLogin={handleLogin} />
          )}
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
