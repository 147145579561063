import React from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";

const AppNavbar = ({ user, onLogout }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (user) {
      const navbarElement = document.querySelector(".navbar-custom");
      if (navbarElement) {
        navbarElement.style.display = "none";
        setTimeout(() => {
          navbarElement.style.display = "";
        }, 0);
      }
    }
  }, [user]);

  return (
    <Navbar bg="white" expand="lg" className="navbar-custom" fixed="top">
      <Container className="navbar-container">
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            src={process.env.PUBLIC_URL + "/logo.png"}
            height="40"
            className="d-inline-block align-top me-2"
            alt="Logo"
          />
          <span className="brand-text">Student Visa Status</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto nav-center">
            <Nav.Link
              as={Link}
              to="/"
              className={`nav-link-custom ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Dashboard
            </Nav.Link>
            {user?.is_admin && (
              <>
                <Nav.Link
                  as={Link}
                  to="/admin"
                  className={`nav-link-custom admin-link ${
                    location.pathname === "/admin" ? "active" : ""
                  }`}
                >
                  <i className="fas fa-cog me-1"></i>
                  Admin Panel
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/analytics"
                  className={`nav-link-custom admin-link ${
                    location.pathname === "/analytics" ? "active" : ""
                  }`}
                >
                  <i className="fas fa-chart-line me-1"></i>
                  Analytics
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav className="nav-right">
            <Nav.Link
              as={Link}
              to="/profile"
              className="nav-link-custom d-flex align-items-center"
            >
              <i className="fas fa-user-circle me-1"></i>
              <span>{user?.name}</span>
              {user?.is_admin && (
                <span className="admin-badge ms-2">Admin</span>
              )}
            </Nav.Link>
            <Button
              variant="outline-danger"
              size="sm"
              className="ms-3"
              onClick={onLogout}
            >
              <i className="fas fa-sign-out-alt me-1"></i>
              Logout
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
