import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Checkbox,
  Skeleton,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import AppNavbar from "./Navbar";
import config from "../config";
import "../styles/Analytics.css";
import { countries } from "../utils/countries";
import axios from "axios";
import { useData } from "../context/DataContext";

// Configure axios defaults
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

// Add new color schemes for counselors and sub-agents
const COUNSELOR_COLORS = [
  "#1976d2", // Blue
  "#00796b", // Teal
  "#7b1fa2", // Purple
  "#2e7d32", // Green
  "#c62828", // Red
  "#f57c00", // Orange
  "#d81b60", // Pink
  "#5d4037", // Brown
  "#455a64", // Blue Grey
  "#827717", // Lime Dark
  "#0277bd", // Light Blue
  "#00695c", // Teal Dark
  "#6a1b9a", // Purple Dark
  "#558b2f", // Light Green
  "#bf360c", // Deep Orange
  "#4527a0", // Deep Purple
  "#00838f", // Cyan
  "#4e342e", // Brown Dark
  "#37474f", // Blue Grey Dark
  "#ff8f00", // Amber
];

const SUBAGENT_COLORS = [
  "#29b6f6", // Light Blue
  "#26a69a", // Teal Light
  "#ba68c8", // Purple Light
  "#66bb6a", // Light Green
  "#ef5350", // Red Light
  "#ffa726", // Orange Light
  "#f06292", // Pink Light
  "#8d6e63", // Brown Light
  "#78909c", // Blue Grey Light
  "#c0ca33", // Lime
  "#42a5f5", // Blue Light
  "#26c6da", // Cyan
  "#ab47bc", // Purple Medium
  "#9ccc65", // Light Green Light
  "#ff7043", // Deep Orange Light
  "#7e57c2", // Deep Purple Light
  "#4dd0e1", // Cyan Light
  "#a1887f", // Brown Light
  "#90a4ae", // Blue Grey Light
  "#ffca28", // Amber Light
];

// Update status colors with meaningful groupings
const STATUS_COLORS = {
  // Rejected statuses - Red spectrum
  "VAL Rejected": "#d32f2f",
  "VAL Rejected and ready for download": "#ef5350",

  // Approved/Completed statuses - Green spectrum
  "VAL Approved and ready for download": "#2e7d32",
  "Application completed": "#43a047",
  "Application Completed": "#43a047",

  // In Progress statuses - Blue spectrum
  "In Progress with Immigration": "#1976d2",
  "In Progress with EMGS": "#1e88e5",

  // Pending statuses - Orange/Yellow spectrum
  "Pending Payment by Institution": "#ff9800",
  "Pending additional document(s) from the institution": "#ffa726",
  "Pending Application Clearance by the relevant authorities": "#ffb74d",
  "Pending Submission of the Student's Passport": "#ffd54f",

  // Initial/Document statuses - Purple spectrum
  "Document checking in progress": "#7b1fa2",
  "Supporting Letter Provided to Institution": "#9575cd",
  "Student Record Created": "#673ab7",

  // Other/Unknown statuses
  "N/A": "#9e9e9e",
  Other: "#757575",
};

// Status grouping categories
const STATUS_GROUPS = {
  Rejected: ["VAL Rejected", "VAL Rejected and ready for download"],
  Approved: [
    "VAL Approved and ready for download",
    "Application completed",
    "Application Completed",
  ],
  "In Progress": ["In Progress with Immigration", "In Progress with EMGS"],
  Pending: [
    "Pending Payment by Institution",
    "Pending additional document(s) from the institution",
    "Pending Application Clearance by the relevant authorities",
    "Pending Submission of the Student's Passport",
  ],
  "Initial Stage": [
    "Document checking in progress",
    "Supporting Letter Provided to Institution",
    "Student Record Created",
  ],
};

const Analytics = ({ user, onLogout }) => {
  const theme = useTheme();
  const { cachedData, updateCache } = useData();
  const [studentStats, setStudentStats] = useState(
    cachedData.analytics || {
      total_students: 0,
      total_active_counselors: 0,
      incomplete_students: 0,
      status_distribution: [],
      percentage_ranges: [],
      university_distribution: [],
      counselor_stats: [],
      counselor_active_stats: [],
      subagent_stats: [],
      package_distribution: [],
      invoice_distribution: [],
      nationality_distribution: [],
      not_started_applications: 0,
    }
  );
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [showSubagents, setShowSubagents] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [showStatusDetails, setShowStatusDetails] = useState(false);
  const [selectedStatusData, setSelectedStatusData] = useState(null);
  const [selectedCounselors, setSelectedCounselors] = useState([]);
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [loading, setLoading] = useState(true);

  // Move getCountryName inside component
  const getCountryName = useCallback((code) => {
    const country = countries.find((c) => c.code === code);
    return country ? country.name : code;
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // Check if we have cached data and it's not too old (e.g., less than 5 minutes old)
      const cachedAnalytics = cachedData.analytics;
      const now = Date.now();
      const cacheAge = now - (cachedAnalytics?.timestamp || 0);
      const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

      if (cachedAnalytics && cacheAge < CACHE_DURATION) {
        setStudentStats(cachedAnalytics.data);
        setLoading(false);
        return;
      }

      // Fetch student statistics if cache is missing or too old
      const statsResponse = await axios.get(
        `${config.API_URL}/analytics/student-stats`
      );

      // Update both state and cache
      setStudentStats(statsResponse.data);
      updateCache("analytics", {
        data: statsResponse.data,
        timestamp: now,
      });
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  }, [cachedData.analytics, updateCache]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to get status group
  const getStatusGroup = useCallback((status) => {
    for (const [group, statuses] of Object.entries(STATUS_GROUPS)) {
      if (statuses.includes(status)) return group;
    }
    return "Other";
  }, []);

  // Get unique values for filters
  const uniqueCounselors = useMemo(() => {
    return [
      ...new Set(studentStats.counselor_stats?.map((item) => item.name) || []),
    ].sort();
  }, [studentStats.counselor_stats]);

  const uniqueUniversities = useMemo(() => {
    return [
      ...new Set(
        studentStats.university_distribution
          ?.filter((item) => item.count > 0) // Only include universities with students
          .map((item) => item.university) || []
      ),
    ].sort();
  }, [studentStats.university_distribution]);

  const uniqueYears = useMemo(() => {
    const years = new Set();
    studentStats.status_distribution?.forEach((item) => {
      if (item.student_year) {
        years.add(item.student_year.toString());
      }
    });
    return Array.from(years).sort((a, b) => b - a);
  }, [studentStats.status_distribution]);

  // Modified process status data function to include filtering
  const processStatusData = useCallback(
    (data) => {
      if (!data || data.length === 0) return [];

      // Create lookup maps for counselors and universities
      const counselorStudents = {};
      studentStats.counselor_stats?.forEach((item) => {
        counselorStudents[item.name] = item.value;
      });

      const universityStudents = {};
      studentStats.university_distribution?.forEach((item) => {
        universityStudents[item.university] = item.count;
      });

      // Start with all data
      let filteredData = [...data];

      // Apply active students filter first
      if (showOnlyActive) {
        filteredData = filteredData.filter((item) => {
          const status = (item.status || "").toLowerCase();
          return (
            !status.includes("completed") &&
            !status.includes("rejected") &&
            !status.includes("closed") &&
            !status.includes("n/a")
          );
        });
      }

      // Apply counselor filter
      if (selectedCounselors.length > 0) {
        const totalSelectedCounselorStudents = selectedCounselors.reduce(
          (sum, counselor) => sum + (counselorStudents[counselor] || 0),
          0
        );
        filteredData = filteredData
          .map((item) => ({
            ...item,
            count: Math.round(
              item.count *
                (totalSelectedCounselorStudents / studentStats.total_students)
            ),
          }))
          .filter((item) => item.count > 0);
      }

      // Apply university filter
      if (selectedUniversities.length > 0) {
        const totalSelectedUniversityStudents = selectedUniversities.reduce(
          (sum, university) => sum + (universityStudents[university] || 0),
          0
        );
        filteredData = filteredData
          .map((item) => ({
            ...item,
            count: Math.round(
              item.count *
                (totalSelectedUniversityStudents / studentStats.total_students)
            ),
          }))
          .filter((item) => item.count > 0);
      }

      // Apply year filter
      if (selectedYears.length > 0) {
        filteredData = filteredData.filter(
          (item) =>
            item.student_year &&
            selectedYears.includes(item.student_year.toString())
        );
      }

      // Sort data by count in descending order
      const sortedData = filteredData.sort((a, b) => b.count - a.count);

      // Calculate total for percentages
      const total = sortedData.reduce((sum, item) => sum + item.count, 0);

      // Add percentage and group information
      return sortedData.map((item) => ({
        ...item,
        percentage: total > 0 ? ((item.count / total) * 100).toFixed(1) : "0.0",
        group: getStatusGroup(item.status),
      }));
    },
    [
      selectedCounselors,
      selectedUniversities,
      selectedYears,
      showOnlyActive,
      studentStats,
      getStatusGroup,
    ]
  );

  // Processed status data
  const processedStatusData = useMemo(() => {
    return processStatusData(studentStats.status_distribution);
  }, [studentStats.status_distribution, processStatusData]);

  // Filter data based on selected group
  const filteredStatusData = useMemo(() => {
    let filtered = processedStatusData;

    if (selectedGroup !== "all") {
      filtered = filtered.filter((item) => item.group === selectedGroup);
    }

    return filtered;
  }, [processedStatusData, selectedGroup]);

  // Calculate summary statistics
  const statusSummary = useMemo(() => {
    if (!processedStatusData.length) return "";

    const approved = processedStatusData.filter(
      (item) => item.group === "Approved"
    );

    if (approved.length > 0) {
      const approvedTotal = approved.reduce((sum, item) => sum + item.count, 0);
      const total = processedStatusData.reduce(
        (sum, item) => sum + item.count,
        0
      );
      const percentage = ((approvedTotal / total) * 100).toFixed(1);

      return `${percentage}% of applications are approved and ready for download`;
    }
    return "";
  }, [processedStatusData]);

  // Custom tooltip content
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <Typography variant="body2" color="textPrimary">
            <strong>{data.status}</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Count: {data.count}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Percentage: {data.percentage}%
          </Typography>
        </Box>
      );
    }
    return null;
  };

  // Add custom tooltip for counselor/sub-agent chart
  const CounselorTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ fontWeight: 600, mb: 0.5 }}
          >
            {data.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Students: {data.value}
          </Typography>
          {showSubagents && data.is_registered !== undefined && (
            <Typography
              variant="body2"
              sx={{
                color: data.is_registered ? "#2e7d32" : "#d32f2f",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                mt: 0.5,
              }}
            >
              <span style={{ fontSize: "1.2em" }}>
                {data.is_registered ? "✓" : "○"}
              </span>
              {data.is_registered ? "Registered" : "Unregistered"}
            </Typography>
          )}
        </Box>
      );
    }
    return null;
  };

  // Handle pie segment click
  const handlePieClick = (data) => {
    if (data && data.payload) {
      setSelectedStatusData(data.payload);
      setShowStatusDetails(true);
    }
  };

  return (
    <>
      <AppNavbar user={user} onLogout={onLogout} />
      <Container
        maxWidth="xl"
        className="analytics-container"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" gutterBottom className="section-title">
          Analytics Dashboard
        </Typography>

        {/* Overview Cards */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            mb: 4,
            minHeight: "120px", // Reserve space for cards
          }}
        >
          {[1, 2, 3, 4].map((index) => (
            <Box
              key={index}
              sx={{
                width: {
                  xs: "100%",
                  sm: "calc(50% - 12px)",
                  md: "calc(25% - 18px)",
                },
              }}
            >
              <Card className="analytics-card">
                <CardContent>
                  {!studentStats.total_students ? (
                    <>
                      <Skeleton width="60%" height={24} />
                      <Skeleton width="40%" height={40} />
                    </>
                  ) : (
                    <>
                      <Typography color="textSecondary" gutterBottom>
                        {index === 1
                          ? "Total Students"
                          : index === 2
                          ? "Active Counselors"
                          : index === 3
                          ? "Active Applications"
                          : "Not Started"}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color:
                            index === 1
                              ? theme.palette.primary.main
                              : index === 2
                              ? theme.palette.secondary.main
                              : index === 3
                              ? theme.palette.error.main
                              : theme.palette.warning.main,
                        }}
                      >
                        {index === 1
                          ? studentStats.total_students
                          : index === 2
                          ? studentStats.total_active_counselors
                          : index === 3
                          ? studentStats.incomplete_students
                          : studentStats.not_started_applications}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>

        {/* Application Status Section */}
        <Paper
          className="chart-container application-status-section"
          sx={{
            p: 3,
            mb: 4,
            minHeight: "600px", // Reserve space for chart
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" className="section-title">
              Application Status Distribution
            </Typography>
            {statusSummary && (
              <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                {statusSummary}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
            }}
          >
            {/* Filters Column */}
            <Box
              sx={{
                width: { xs: "100%", md: "25%" },
                minWidth: { md: "250px" },
              }}
            >
              {/* Additional Filters */}
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
                  Filters
                </Typography>

                {/* Counselor Filter */}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel>Counselors</InputLabel>
                  <Select
                    multiple
                    value={selectedCounselors}
                    onChange={(e) => setSelectedCounselors(e.target.value)}
                    label="Counselors"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {uniqueCounselors.map((counselor) => (
                      <MenuItem key={counselor} value={counselor}>
                        <Checkbox
                          checked={selectedCounselors.indexOf(counselor) > -1}
                        />
                        {counselor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* University Filter */}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel>Universities</InputLabel>
                  <Select
                    multiple
                    value={selectedUniversities}
                    onChange={(e) => setSelectedUniversities(e.target.value)}
                    label="Universities"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {uniqueUniversities.map((university) => (
                      <MenuItem key={university} value={university}>
                        <Checkbox
                          checked={
                            selectedUniversities.indexOf(university) > -1
                          }
                        />
                        {university}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Year Filter */}
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel>Student Years</InputLabel>
                  <Select
                    multiple
                    value={selectedYears}
                    onChange={(e) => setSelectedYears(e.target.value)}
                    label="Student Years"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {uniqueYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        <Checkbox checked={selectedYears.indexOf(year) > -1} />
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Active Students Toggle */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={showOnlyActive}
                      onChange={(e) => setShowOnlyActive(e.target.checked)}
                    />
                  }
                  label="Show Only Active Students"
                  sx={{ mb: 2 }}
                />
              </Box>

              {/* Status Group Filter */}
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
                  Filter by Category
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Button
                    size="small"
                    variant={selectedGroup === "all" ? "contained" : "outlined"}
                    onClick={() => setSelectedGroup("all")}
                    className="status-group-button"
                  >
                    All Categories
                  </Button>
                  {Object.keys(STATUS_GROUPS).map((group) => (
                    <Button
                      key={group}
                      size="small"
                      variant={
                        selectedGroup === group ? "contained" : "outlined"
                      }
                      onClick={() => setSelectedGroup(group)}
                      className="status-group-button"
                      sx={{
                        justifyContent: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      {group}
                    </Button>
                  ))}
                </Box>
              </Box>
            </Box>

            {/* Chart Column */}
            <Box
              sx={{
                flex: 1,
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {filteredStatusData.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={filteredStatusData}
                      dataKey="count"
                      nameKey="status"
                      cx="50%"
                      cy="50%"
                      outerRadius={180}
                      onClick={handlePieClick}
                      cursor="pointer"
                    >
                      {filteredStatusData.map((entry) => (
                        <Cell
                          key={entry.status}
                          fill={
                            STATUS_COLORS[entry.status] ||
                            STATUS_COLORS["Other"]
                          }
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      formatter={(value, entry) => (
                        <span
                          style={{
                            color:
                              STATUS_COLORS[value] || STATUS_COLORS["Other"],
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              backgroundColor:
                                STATUS_COLORS[value] || STATUS_COLORS["Other"],
                              display: "inline-block",
                            }}
                          />
                          {value} ({entry.payload.percentage}%)
                        </span>
                      )}
                    />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <Typography variant="h6" color="textSecondary">
                  There's no data to show
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>

        {/* Other Charts Section */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            "& .chart-container": {
              minHeight: "400px", // Reserve space for charts
            },
          }}
        >
          {/* Counselor Distribution */}
          <Paper
            className="chart-container counselor-status-section"
            sx={{ p: 3, width: "100%" }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography variant="h5" className="section-title">
                Students by {showSubagents ? "Sub-agent" : "Counselor"}{" "}
                Distribution
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 3,
              }}
            >
              {/* Filters Column */}
              <Box
                sx={{
                  width: { xs: "100%", md: "25%" },
                  minWidth: { md: "250px" },
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 600 }}
                  >
                    Filters
                  </Typography>

                  {/* Active Students Toggle */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showActiveOnly}
                        onChange={(e) => setShowActiveOnly(e.target.checked)}
                      />
                    }
                    label="Show Only Active Students"
                    sx={{ mb: 2 }}
                  />

                  {/* Show Subagents Toggle */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showSubagents}
                        onChange={(e) => setShowSubagents(e.target.checked)}
                      />
                    }
                    label="Show Sub-agents"
                    sx={{ mb: 2 }}
                  />
                </Box>
              </Box>

              {/* Chart Column */}
              <Box
                sx={{
                  flex: 1,
                  minHeight: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={
                          showSubagents
                            ? studentStats.subagent_stats || []
                            : showActiveOnly
                            ? studentStats.counselor_active_stats || []
                            : studentStats.counselor_stats || []
                        }
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={180}
                      >
                        {(showSubagents
                          ? studentStats.subagent_stats || []
                          : showActiveOnly
                          ? studentStats.counselor_active_stats || []
                          : studentStats.counselor_stats || []
                        )?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              showSubagents
                                ? SUBAGENT_COLORS[
                                    index % SUBAGENT_COLORS.length
                                  ]
                                : COUNSELOR_COLORS[
                                    index % COUNSELOR_COLORS.length
                                  ]
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip content={<CounselorTooltip />} />
                      <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                        formatter={(value, entry) => (
                          <span
                            style={{
                              color: showSubagents
                                ? SUBAGENT_COLORS[
                                    entry.payload.index % SUBAGENT_COLORS.length
                                  ]
                                : COUNSELOR_COLORS[
                                    entry.payload.index %
                                      COUNSELOR_COLORS.length
                                  ],
                              display: "inline-flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <span
                              style={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                                backgroundColor: showSubagents
                                  ? SUBAGENT_COLORS[
                                      entry.payload.index %
                                        SUBAGENT_COLORS.length
                                    ]
                                  : COUNSELOR_COLORS[
                                      entry.payload.index %
                                        COUNSELOR_COLORS.length
                                    ],
                                display: "inline-block",
                              }}
                            />
                            {value} ({entry.payload.value})
                          </span>
                        )}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </Box>
            </Box>
          </Paper>

          {/* Top 10 Universities */}
          <Box sx={{ width: "100%" }}>
            <Paper className="chart-container" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom className="section-title">
                Top 10 Universities
              </Typography>
              <Box
                sx={{
                  height: 300,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                  <ResponsiveContainer>
                    <BarChart
                      data={studentStats.university_distribution?.slice(0, 10)}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="university" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="count"
                        name="Students"
                        fill={theme.palette.secondary.main}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
            </Paper>
          </Box>

          {/* Top 10 Nationalities */}
          <Box sx={{ width: "100%" }}>
            <Paper className="chart-container" sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom className="section-title">
                Top 10 Nationalities
              </Typography>
              <Box
                sx={{
                  height: 300,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                  <ResponsiveContainer>
                    <BarChart
                      data={studentStats.nationality_distribution?.map(
                        (item) => ({
                          ...item,
                          nationality: getCountryName(item.nationality),
                        })
                      )}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="nationality" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="count"
                        name="Students"
                        fill={theme.palette.info.main}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
            </Paper>
          </Box>
        </Box>

        {/* Status Details Dialog */}
        <Dialog
          open={showStatusDetails}
          onClose={() => setShowStatusDetails(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {selectedStatusData?.status} Details
            {selectedStatusData && (
              <Typography variant="subtitle2" color="textSecondary">
                Category: {getStatusGroup(selectedStatusData.status)}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              There are {selectedStatusData?.count} applications (
              {selectedStatusData?.percentage}%) with status "
              {selectedStatusData?.status}".
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowStatusDetails(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default Analytics;
